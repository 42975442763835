import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import {doc,updateDoc } from "firebase/firestore";
import { database } from '../../../firebase';
import { useAuth } from '../../../hooks/AuthContext';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme => theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme => theme.spacing(1),
    },
}));

export default function IncubatorEditForm({ open, onClose,incubatorName,website,docID }) {
    const { control, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { currentUser } = useAuth();
    const [documentID, setDocumentID] = useState('');


    useEffect(() => {

        setValue("incubatorName", incubatorName || "");
        setValue("website", website || "");
        setDocumentID(docID);
       
      }, [incubatorName, website, docID, setValue])
    

    const onSubmit = async (data) => {
     

        await updateDoc(doc(database, "startupInformation", currentUser.uid, "incubator",documentID), {
            incubatorName: data.incubatorName,
            website: data.website,
            createdAt: new Date()
        });
        reset();
        onClose();


    };



    return (
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick
                fullWidth
                borderRadius="10px"
                maxWidth="xs"
            >
                <DialogTitle sx={{ marginLeft: 2, p: 2 }} id="customized-dialog-title">
                   Update  Incubator / Accelerator
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <form onSubmit={handleSubmit(onSubmit)}>

                <FormControl fullWidth >

                <DialogContent>
                    <FormGroup>
                        <FormLabel>Incubator / Accelerator Name</FormLabel>
                        <Controller
                            name="incubatorName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Name is required' }}
                            render={({ field }) => <TextField {...field} fullWidth />}
                        />

                        {errors.incubatorName && <p className="error-text">{errors.incubatorName.message}</p>}

                    </FormGroup>

                </DialogContent>
                <DialogContent>
                    <FormGroup>
                        <FormLabel>Website</FormLabel>
                        <Controller
                            name="website"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Website is required' }}
                            render={({ field }) => <TextField {...field} fullWidth />}
                        />
                        {errors.website && <p className="error-text">{errors.website.message}</p>}

                    </FormGroup>


                </DialogContent>
                </FormControl>
                <DialogActions style={{ justifyContent: "space-between" }}>
                    <Button type='submit' disabled={false} sx={{ marginLeft: "20px", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} variant='outlined' autoFocus >
                        Update

                    </Button>

                </DialogActions>
                </form>

            </BootstrapDialog>
    )
}
