import { Button, FormControl, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LabelBackButton from '../../../component/LabelBackButton';

export default function Equity() {
    const { control, formState: { errors }, setValue } = useForm();
    const location = useLocation();
    const navigate = useNavigate();

    console.log("roundStage" +location.state.roundStage);
    console.log("instrumentType" +location.state.instType);
    console.log("roundSize" +location.state.targetSize);
    const equity = useWatch({
        control,
        name: 'equity', 
    });

    return (
        <div>
                <LabelBackButton title={"Equity"} />
                <p style={{marginLeft:"4rem",marginTop:"1rem"}}>Equity given to the round</p>
           
            <FormControl  sx={{width:"52.5%",marginTop:"3rem"}}>
                <Controller
                    name="equity"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'equity',
                        minLength:3

                    }}
                    render={({ field }) => <TextField
                       
                     {...field} fullWidth type="number" placeholder="Ex: 15%"
                     label="Equity"

                    />}
                />

                {errors.roundSize && <p className="error-text">{errors.roundSize.message}</p>}

            </FormControl>

          <br/>

        
  <Button 
  variant='outlined' disabled={!equity}

  onClick={() =>
    {
         navigate("tm", { state: { roundStage: location.state.roundStage, instType: location.state.instType, targetSize: location.state.targetSize,equity:equity } })}}
  
  sx={{marginTop:"2rem", color: "black", borderColor: "black", textTransform: "none" ,width:"20%"}} >Continue</Button>

  
        </div>
    )
}
