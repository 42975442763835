import React, { useEffect, useState } from 'react'
import logo from "../../assets/dozerologo.png";
import { EmailRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useAuth } from '../../hooks/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import EmailVerificationDialog from './EmailVerificationDialog';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../firebase';
import {onIdTokenChanged } from "firebase/auth";


export default function EmailVerified() {
    const { logout,emailVerification ,currentUser} = useAuth();
    const navigate = useNavigate();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(()=>{


      onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            console.log("emailVerifeid page", uid )
            if(user.emailVerified){
              navigate("/",{ replace: true })

            }
          }
          
       
        });
       
  }, [])

    const handleVerificationRequest = async() => {
    try{
        await emailVerification().then(() => {
            console.log("emailverification sent");
           

            // Email verification sent!
            // ...
          });
    }catch(e){
        console.log("emailverification error " + e);
    }
      setIsDialogOpen(true);
    };
  
    const closeDialog = () => {
      setIsDialogOpen(false);
    };

  return (

    <>
          <div className="logo"><img src={logo} style={{}} alt='Dozero logo'></img></div>
          <div style={{width:"100%" ,height:"80vh",gap:"2rem", display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",alignContent:"center"}}>
      <h2 style={{color:"#216363"}}>You're one step closer</h2>
      <EmailRounded fontSize='large' sx={{color:"#216363" ,scale:"2.0"}} />
      <br/>
      <p style={{color:"#216363",fontFamily:"dmsans"}}>We have sent an email to {currentUser.email} with a link<br/>to verify your email. You need to verify your email to complete registration</p>
       
       <div style={{display:"flex", alignItems:"center",gap:"1rem"}}>
       <Button onClick={async()=>handleVerificationRequest() } variant='text' sx={{ color: "black", borderColor: "black", textTransform: "none",textDecorationLine:"underline" }}>Send email again</Button>
       <span>or</span>
       <Button variant='text' onClick={async()=>{
        navigate("/",{replace:true});
       await logout();
       }} sx={{ color: "black", borderColor: "black", textTransform: "none",textDecorationLine:"underline" }}>Cancel this request</Button>
 
       </div>

       <div><Typography>If your page is not automatically redirect after email verification.Please click <Button sx={{color:"#216363",textDecoration:"underline"}}  onClick={()=>window.location.reload()} >Here</Button></Typography></div>
       <EmailVerificationDialog open={isDialogOpen} onClose={closeDialog} />

    </div>
    </>
   
  )
}
