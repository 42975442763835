import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { onCurrentUserSubscriptionUpdate } from '@invertase/firestore-stripe-payments';
import { payments } from "../firebase";

const SubscriptionContext = createContext();

export const useStripeSubscription = () => {
    return useContext(SubscriptionContext);
  };
  

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const { currentUser } = useAuth();


  useEffect(()=>{
    if(currentUser){
        const subscriptionUpdateListener = onCurrentUserSubscriptionUpdate(
            payments,
            (snapshot) => {
    
              for (const change of snapshot.changes) {
                console.log("SubscriptionProvider " + change.subscription.status);
             
                    
                    setSubscription(change.subscription);

                
              }
        
            }
          );
    
          return () => {
            subscriptionUpdateListener();
          };
    }
    
},[currentUser]);
const value = {
  subscription
  
 };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

