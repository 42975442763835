import React from 'react'
import DataRoomComponent from '../DataRoomComponent/DataRoomComponent'
import { useParams } from 'react-router-dom'

export default function MainDataRoom() {
    const param = useParams();

  return (
   <DataRoomComponent title={param.name} fileTitle={"File Name"} fileType={"File Type"} />
  )
}
