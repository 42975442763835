import React, { useState } from 'react'
import LabelBackButton from '../../../component/LabelBackButton'
import { Controller, useForm } from 'react-hook-form'
import { Button, FormControl, FormLabel, InputAdornment, Stack, Switch, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { Timestamp, addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { database } from '../../../firebase';
import { useAuth } from '../../../hooks/AuthContext';
import ToggleSwitch from '../ToggleSwitch';



const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


export default function Term() {
  const { control,handleSubmit, formState: { errors }, setValue,getValues } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [valuationCap, setValuationCap] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [mfnOnly, setMfnOnly] = useState(false);

  const [minCheckSize, setMinCheckSize] = useState(false);
  const [proRata, setProRata] = useState(false);
  const [additionalTerm, setAdditionalTerm] = useState(false);

  const { currentUser } = useAuth();




  // Function to calculate post-money valuation
  function calculatePostMoneyValuation(investmentAmount, percentageAcquired) {
    // Make sure to convert percentage to a decimal
    const cal= Math.floor(investmentAmount / (percentageAcquired / 100));
    console.log("cal " + cal);
    return cal;
  }

  // Function to calculate pre-money valuation
  function calculatePreMoneyValuation(postMoneyValuation, investmentAmount) {
    return postMoneyValuation - investmentAmount;
  }



  async function uploadInvestmentRound(data) {
   const isSAFE= extractFirstWord(location.state.instType)!='Equity';
  let terms={};
   if(isSAFE){
    if(valuationCap){
      terms['valuationCap'] = data.valuationCap;

    }
    if(discount){
      terms['discount'] = data.discount;

    }
  
    terms['mfnOnly'] = mfnOnly;

    
  
   }
   if(minCheckSize){
    terms['minCheckSize'] = data.minCheckSize;

  }
  if(additionalTerm){
    terms['additionalTerms'] = data.additionalTerm;

  }

  terms['proRata'] = proRata;



    const postMoneyValuation = calculatePostMoneyValuation(location.state.targetSize, location.state.equity);
    const preMoneyValuation = calculatePreMoneyValuation(postMoneyValuation, location.state.targetSize);

    try {
      await setDoc(doc(database, "investmentRound", currentUser.uid), {
        roundType: location.state.roundStage,
        instrumentType: location.state.instType,
        targetSize: location.state.targetSize,
        equity: location.state.equity,
        preMoneyValuation: preMoneyValuation,
        postMoneyValuation: postMoneyValuation,
        terms: terms,
        createdAt: Timestamp.now()
      });

      navigate("/investmentround", { replace: true });
    } catch (e) {
      console.log("e " + e);
    }


  }
  function extractFirstWord(sentence) {
    // Split the sentence into an array of words
    const words = sentence.split(' ');
  
    // Return the first word
    return words[0];
  }
  const onSubmit = async (data) => {

    await uploadInvestmentRound(data);
  
  };

  return (
    <>
    {   console.log( )}
      <LabelBackButton title={"Terms"} />
      <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl sx={{marginTop:"2rem",marginLeft:"2rem"}}>
      {extractFirstWord(location.state.instType)!='Equity' && 
      <Stack spacing={3} direction={"column"}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>
          <div><b>Valution Cap</b></div>
          This is the maximum valuation a SAFE converts at. A lower cap means investors receive more company shares upon conversion</div>
              <AntSwitch checked={valuationCap} onChange={()=>setValuationCap(!valuationCap)} sx={{ m: 1 }} />
      </div>
    {valuationCap &&  <div>
      <FormControl  sx={{ width: "65%"}}>
                <Controller
                    name="valuationCap"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Valuation Cap',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Invalid round size. Please enter digits only.',
                          },

                    }}
                    render={({ field }) => <TextField
                    
                   
                        InputProps={{
                            startAdornment: <InputAdornment position="start">


                            <p style={{ fontSize: "1rem"}}>$ </p>




                        </InputAdornment>,
                            

                               
                          
                        }}
                        {...field} fullWidth type="number"

                        

                    />}
                />

                {errors.valuationCap && <p className="error-text">{errors.valuationCap.message}</p>}

            </FormControl>

      </div>}


      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>
          <div><b>Discount</b></div>
          This is a % discount to the priced round valuation that a SAFE converts at. The most common discount value is 20%. If a cap and discount are both enabled, the SAFE will convert at the more investor-favorable of two</div>
              <AntSwitch checked={discount} onChange={()=>setDiscount(!discount)} sx={{ m: 1 }} />
      </div>
    {discount &&  <div>
      <FormControl  sx={{ width: "65%"}}>
                <Controller
                    name="discount"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Discount',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Invalid round size. Please enter digits only.',
                          },

                    }}
                    render={({ field }) => <TextField
                    
                    
                        InputProps={{
                          endAdornment: <InputAdornment position="end">


                            <p style={{ fontSize: "1rem"}}>%</p>

                        </InputAdornment>,
                            

                               
                          
                        }}
                        {...field} fullWidth type="number"

                        

                    />}
                />

                {errors.discount && <p className="error-text">{errors.discount.message}</p>}

            </FormControl>

      </div>}


      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>
          <div><b>MFN Only</b></div>
          SAFE holders receive conversion terms based on the most favorable terms offered to investors in the next priced equity round. Note: valuation and discount terms must be disabled in order to set a SAFE to MFN Only.</div>
              <AntSwitch disabled={discount || valuationCap} checked={mfnOnly} onChange={()=>setMfnOnly(!mfnOnly)} sx={{ m: 1 }} />
      </div>


      </Stack>}
      

      <Stack spacing={2} sx={{marginTop:"1rem"}}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>Minimum Check Size</div>
              <AntSwitch checked={minCheckSize} onChange={()=>setMinCheckSize(!minCheckSize)} sx={{ m: 1 }} />
      </div>
    {minCheckSize &&  <div>
      <FormControl  sx={{ width: "65%"}}>
                <Controller
                    name="minCheckSize"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Mininum check size',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Invalid round size. Please enter digits only.',
                          },

                    }}
                    render={({ field }) => <TextField
                    
                   
                        InputProps={{
                            startAdornment: <InputAdornment position="start">


                            <p style={{ fontSize: "1rem"}}>$ </p>




                        </InputAdornment>,
                            

                               
                          
                        }}
                        {...field} fullWidth type="number"

                        

                    />}
                />

                {errors.minCheckSize && <p className="error-text">{errors.minCheckSize.message}</p>}

            </FormControl>

      </div>}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>Pro Rata</div>
              <AntSwitch checked={proRata} onChange={()=>setProRata(!proRata)} sx={{ m: 1 }} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "40rem", alignItems: "center" }}>
        <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>Additional Terms</div>
              <AntSwitch checked={additionalTerm} onChange={()=>setAdditionalTerm(!additionalTerm)} sx={{ m: 1 }} />
      </div>
    {additionalTerm &&  <div>
      <FormControl  sx={{ width: "65%"}}>
                <Controller
                    name="additionalTerm"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Additional Terms',
                      

                    }}
                    render={({ field }) => <TextField
                    
                      multiline
                      rows={4}
                
                        {...field} fullWidth type="text"
                        placeholder='Add any additional terms here'

                        

                    />}
                />

                {errors.additionalTerm && <p className="error-text">{errors.additionalTerm.message}</p>}

            </FormControl>

      </div>}
      </Stack>
      </FormControl>
      <Button
      
        variant='outlined' type='submit' sx={{marginTop:"3rem",marginLeft:"2rem", display:"flex",flexDirection:"row", color: "black", borderColor: "black", textTransform: "none", width: "20%" }} >Create</Button>
 </form>
    </>
  )
}
