import {initializeApp} from "firebase/app";
import { getAuth ,sendEmailVerification} from "firebase/auth";
import { getStorage,ref } from "firebase/storage";
import {getFirestore}  from "firebase/firestore"
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZQXkTEus3oGUVFSd3chpUG6KTdSjBCsw",
  authDomain: "dozero-631a3.firebaseapp.com",
  projectId: "dozero-631a3",
  storageBucket: "dozero-631a3.appspot.com",
  messagingSenderId: "268223816453",
  appId: "1:268223816453:web:23cdaf7052d0569c2ecd6c",
  measurementId: "G-F99M8V9HVK"
};

const firebaseApp = initializeApp(firebaseConfig);


// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6Lc9O-4oAAAAAKjEM2oTYMG9zP0wwiw9M2G4WbsC'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });


// Initialize Firebase Authentication ,Storage and Firestore and get a reference to the service
export const payments = getStripePayments(firebaseApp, {
  productsCollection: "packages",
  customersCollection: "users",
});



export const auth = getAuth(firebaseApp);
export const emailVerification = sendEmailVerification;

export const storage = getStorage(firebaseApp);
export const storageRef = ref;

export const database = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
const analytics = getAnalytics(firebaseApp);
export default firebaseApp;


