
import ReactDOM from 'react-dom/client';
import React from 'react'
import './index.css';
import App from './App';
import AuthProvider from './hooks/AuthContext';
import { ThemeProvider, createTheme } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubscriptionProvider } from './hooks/useStripeSubscription';


const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
          },
        },
      },
    },
  },
});



function onChange(value) {
  console.log("Captcha value:", value);
}

root.render(
  //   <ReCAPTCHA
  //   sitekey="6Lc9O-4oAAAAAKjEM2oTYMG9zP0wwiw9M2G4WbsC"
  //   onChange={onChange}
  // />,
  <React.StrictMode>
    <AuthProvider>
      <SubscriptionProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </SubscriptionProvider>
    </AuthProvider>
  </React.StrictMode>
);
