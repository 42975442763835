import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slider, Box, Typography, CircularProgress, LinearProgress, IconButton } from '@mui/material';
import Cropper from 'react-easy-crop';
import { v4 as uuidv4 } from 'uuid';

import { storage } from '../../firebase'; // Import your Firebase configuration here
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { PhotoCamera } from '@mui/icons-material';
import { useAuth } from '../../hooks/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, database } from '../../firebase';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';

function UploadDialog({ open, onClose,isProfilePic }) {
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const { currentUser } = useAuth();

  function showPermissionDeniedError() {
    return Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: "Permission Denied.Contact Support team",
      showConfirmButton: true,

    })

  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  async function getCroppedImg(imageSrc, pixelCrop) {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    // New canvas size
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
  
    return new Promise((resolve, reject) => {
      image.onload = () => {
        // Draw the cropped image on the canvas
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
  
        // Convert the canvas to a blob
        canvas.toBlob(blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'newFile.jpeg'; // you might want to dynamically generate a name here
          resolve(blob);
        }, 'image/jpeg');
      };
      image.onerror = () => {
        reject(new Error('Image load error'));
      };
    });
  }
  

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
      });
    }
  };

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleUpload = useCallback(async () => {
    try {
      setUploading(true);
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      let compressedFile;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      }
      try {
         compressedFile = await imageCompression(croppedImage, options);

      } catch (error) {
        console.error('Error compressing image:', error);
      }
  
      let filePath;
      if(isProfilePic){
        filePath=`users/${currentUser.email}/profile/${imageFile.name}_${uuidv4()}`;
      }else{
        filePath = `users/${currentUser.email}/startupInformation/identity/${imageFile.name}_${uuidv4()}`;

      }
      const storageRef = ref(storage, filePath);

      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress updates here if you want to show progress in the UI
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error('Upload failed', error);
          setUploading(false);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

            console.log("dowwwd " + downloadURL);

            if(isProfilePic){
            
               
                const profileRef = doc(database, "users", currentUser.uid);
            
                try {
                  await updateDoc(profileRef, {
                    "profileImageUrl": downloadURL,
          
                  })
                } catch (e) {
                  showPermissionDeniedError();
                }
            
              
            }else{

              const identityRef = doc(database, "startupInformation", currentUser.uid, "startupIdentity", "identity");
            
              try {

                await updateDoc(identityRef, {
                    "fileUrl": downloadURL,
                });
              } catch (e) {
                showPermissionDeniedError();
              }
          

            }
            setUploading(false);
            setImageFile(null);
            setImageSrc(null);
            
            onClose();
          });
        }
      );
    } catch (error) {
      console.error('Error cropping image:', error);
      setUploading(false);
    }
  }, [imageFile, croppedAreaPixels, imageSrc, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth slotProps ={{ backdrop: false }}>
      <DialogTitle>{isProfilePic ?"Upload Profile Picture" : "Upload Startup Photo"}</DialogTitle>
   
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: 'divider',
              backgroundSize: 'cover',
              backgroundImage: `url(${imagePreview})`,
            }}
          >
            {!imagePreview && (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input hidden accept="image/*" type="file" onChange={handleFileChange} />
                <PhotoCamera sx={{ fontSize: 30 }} />
              </IconButton>
            )}
          </Box>
          {imagePreview && (
            <Button variant="text" component="label">
              Change
              <input hidden accept="image/*" type="file" onChange={handleFileChange} />
            </Button>
          )}
          {progress > 0 && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="caption" align="center">{`${Math.round(progress)}%`}</Typography>
            </Box>
          )}
        </Box>
     
 
        <Box sx={{ position: 'relative', height: 400, width: '100%', marginBottom: 2,marginLeft:5,marginRight:10 }}>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
        </Box>
        {imageSrc && (
          <div style={{width:"20rem",display:"flex",flexDirection:"row",justifyContent:"end",marginLeft:"5rem"}}>
            <Typography variant="overline">Zoom</Typography>
            <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={handleZoomChange} />
          </div>
        )}
    
      <DialogActions>
        <Button onClick={onClose} disabled={uploading}>Cancel</Button>
        <Button onClick={handleUpload} color="primary" variant="contained" disabled={!imageSrc || uploading}>
          {uploading ? <CircularProgress size={24} /> : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadDialog;
