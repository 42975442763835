import React from 'react'
import "./PageHeadingLabel.css"

export default function PageHeadingLabel({headingText}) {
  return (
    <div className='heading-label'>
      {headingText}
    </div>
  )
}
