import React from 'react'
import './dataroom.css'
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import ListComponent from '../../component/List/ListComponent';
const DataRoom = () => {

    const dataList = [
        <b>Investor deck</b>,
        <b>Pitch deck</b>,
        <b>Intro deck</b>,
        <b>Finacial model</b>,
        <b>Capital allocaton</b>,
        <b>Product demo</b>,
        'Bussines roadmap',
        'Sales piples',
        'Commercial agreements',
        'Marketing plan',
        'Historical sales',
        'Technology & feasibility analysis',
        'Advisor & NED contracts',
        'Company registration & articles of association',   
        'References'

    ];
    const pathList = [
        'Investor deck',
        'Pitch deck',
        'Intro deck',
        'Finacial model',
        'Capital allocaton',
        'Product demo',
        'Bussines roadmap',
        'Sales piples',
        'Commercial agreements',
        'Marketing plan',
        'Historical sales',
        'Technology & feasibility analysis',
        'Advisor & NED contracts',
        'Company registration & articles of association',   
        'References'

    ];

    return (


        <div className='mobile-friendly'>
            <PageHeadingLabel headingText={"Data Room"} />
            <br />
            <ListComponent pathList={pathList} dataList={dataList} />


        </div>





    );
}


export default DataRoom