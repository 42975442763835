import React from 'react';
import { Avatar, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function ProfileAvatar({ fileURL, onEdit }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Avatar
        alt="User Profile"
        sx={{ width: 130, height: 130 }}
        src={fileURL ? fileURL : process.env.PUBLIC_URL + "/pic/user_profile.png"}
      />
      <IconButton
        sx={{
          position: 'absolute',
          bottom: 20,
          right: 30,
          transform: 'translate(50%, 50%)', // Center the button on the bottom right corner
          backgroundColor: 'white',
          color: 'grey',
          boxShadow: '0 2px 4px rgba(0,0,0,0.5)', // Add shadow for depth
          '&:hover': {
            backgroundColor: 'white',
            color: 'black', // Change color on hover for better interaction feedback
            boxShadow: '0 4px 8px rgba(0,0,0,0.5)', // Stronger shadow on hover
          },
          '& .MuiIconButton-label': {
            transition: '0.3s' // Smooth transition for the hover effect
          },
          borderRadius: '50%', // Circular shape
          border: '2px solid', // Add border for more definition
          borderColor: 'background.paper', // Use theme's background color for the border
          padding: '0.25rem', // Space around the icon
          minWidth: 'auto', // Remove the minimum width property if exists
          minHeight: 'auto', // Remove the minimum height property if exists
        }}
        onClick={onEdit}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
}

export default ProfileAvatar;
