import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  

const ToggleSwitch = ({ control, name, defaultValue, rules,checked }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "30rem", alignItems: "center" }}>
      <div style={{ fontSize: "1rem", fontFamily: "dmsans" }}>Minimum Check Size</div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => <AntSwitch {...field}  sx={{ m: 1 }} defaultChecked />}
      />

    
    </div>
  );
};

export default ToggleSwitch;
