import React from 'react'
import TitleAndBackButton from '../TitleAndBackButton/TitleAndBackButton'
import { useMediaQuery } from 'react-responsive'

export default function DataRoomComponent(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
  let dialogTitle;
  if (props.title === "3 minutes Pitch (Video)") {
    dialogTitle="Upload Video";

  } else if (props.title === "3 minutes on UX (Video)") {
    dialogTitle="Upload Video";



  } else if (props.title === "3 minutes on Tech (Video)") {

    dialogTitle="Upload Video";



  }else{
    dialogTitle="Upload Document";

  }

 
  return (
    
    <div>
<TitleAndBackButton fileTitle={props.fileTitle} fileType={props.fileType} title={props.title} buttonText={isMobile ? "Upload" :dialogTitle} dialogTitle={props.title} />

    </div>
  )
}
