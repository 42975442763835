import React, { useCallback, useEffect, useState } from "react";
import "./onboarding.css";
import { Box, Button, Divider, Icon, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import PageHeadingLabel from "../../component/PageHeadingLabel/PageHeadingLabel";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MonitorIcon from '@mui/icons-material/Monitor';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import MoneyIcon from '@mui/icons-material/Money';
import { CheckCircle, CloudUploadOutlined, Done } from "@mui/icons-material";
import SideNavRight from "../../component/SideNavRght/SideNavRight";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../hooks/AuthContext";
import { database } from '../../firebase';
import startupProfile from "../../assets/startup_profile.svg";


const OnBoarding = () => {

  const dataList = [
    'Complete the identity of your startup',
    'Complete the one liner',
    'Founders Information'
  ];
  const pathList = [
    '/startupinformation/startup_identity',
    '/startupinformation/startup_identity',
    '/startupinformation/founder',

  ];
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  const [identity, setIdentity] = useState(false);
  const [oneLiner, setOneLiner] = useState(false);
  const [founderInformation, setFounderInformation] = useState(false);
  const [startProfileLinearProgress,setStartProfileLinearProgress] = useState(0)
  const [startProfileStepsCompleted,setStartProfileStepsCompleted] = useState("not completed")


  const [investorDeck, setInvestorDeck] = useState(false);
  const [introDeck, setIntroDeck] = useState(false);
  const [financialModel, setFinancialModel] = useState(false);
  const [capitalAllocation, setCapitalAllocation] = useState(false);


  const [startupOpportunity, setStartupOpportunity] = useState(false);


  const [pitchStory, setPitchStory] = useState(false);




  const [captable,setCaptable] = useState(false)
  const [investmentRound,setInvestmentRound] = useState(false)

  const [username, setUsername] = React.useState('');


  const { currentUser } = useAuth();


    // Step 2: Function to update the sum
    const addToSum = (value) => {
      // Update the sum by adding the new value
      
      setStartProfileLinearProgress((prevSum) => prevSum + value);
    };

  const fetchUser = useCallback(async () => {
    const docRef = doc(database, "users", currentUser.uid);
    
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setUsername(doc.data().name);
  
      } else {
  
        console.log("No such document!");
      }
  });   
  }, [])



 

  useEffect(()=>{
    fetchUser();

    async function getStartupIdentity(){
      const identity = doc(database, "startupInformation", currentUser.uid,"startupIdentity","identity");
      const docE = await getDoc(identity);
      const startIdentityExist = docE.exists();
      if(startIdentityExist){
        addToSum(34)
        setStartProfileStepsCompleted(1)
      }
      setIdentity(startIdentityExist);
    }


    async function getOneLiner(){
      const oneLinerDocRef = doc(database, "startupInformation", currentUser.uid,"startupIdentity","identity");
      const oneLinerDoc = await getDoc(oneLinerDocRef);
      if(oneLinerDoc.exists()){
      
      setOneLiner(oneLinerDoc.data().startupOneliner.length >0);
      }
    }


    async function getFounderInfo(){
      const oneLinerDocRef = collection(database, "startupInformation", currentUser.uid,"founders");
      const oneLinerDoc = await getDocs(oneLinerDocRef);
      if(oneLinerDoc.docs.length > 0){
      setFounderInformation(true);
      }
    }


    async function getCaptableInfo(){
      let myRef = collection(database, 'captable', currentUser.uid, "shareholders")

      const oneLinerDoc = await getDocs(myRef);
      if(oneLinerDoc.docs.length > 0){
      setCaptable(true);
      }
    }

    async function getinvestmnetRoundInfo(){
      const investmentRef = doc(database, "investmentRound", currentUser.uid);
      const investmenDoc = await getDoc(investmentRef);
    
      
      setInvestmentRound(investmenDoc.exists());
      
    }


    async function getInvestorDeck(){
      const investorDocRef = collection(database, 'dataRoom', currentUser.uid, "investor");
      const investorDoc = await getDocs(investorDocRef);
      if(investorDoc.docs.length > 0){
      setInvestorDeck(true);
      }
    }

    async function getIntroDeck(){
      const introDocRef = collection(database, 'dataRoom', currentUser.uid, "intro");
      const introDoc = await getDocs(introDocRef);
      if(introDoc.docs.length > 0){
      setIntroDeck(true);
      }
    }


    async function getFinancialModel(){
      const financialDocRef = collection(database, 'dataRoom', currentUser.uid, "financial");
      const financialDoc = await getDocs(financialDocRef);
      if(financialDoc.docs.length > 0){
      setFinancialModel(true);
      }
    }
    async function getCapitalAllocation(){
      const captialDocRef = collection(database, 'dataRoom', currentUser.uid, "capital");
      const captialDoc = await getDocs(captialDocRef);
      if(captialDoc.docs.length > 0){
      setCapitalAllocation(true);
      }
    }

    async function getPitchStoryInfo(){
      const pitchStoryRef = collection(database, "pitchStory", currentUser.uid,"data");
   
      const pitchStoryDoc = await getDocs(pitchStoryRef);
      if(pitchStoryDoc.docs.length > 0){
      setPitchStory(true);
      }
      
    }
    async function getStartupOpportunityInfo(){
    const pitchCOL = collection(database, 'startupopportunity', currentUser.uid, "pitch")
    const uxCOL = collection(database, 'startupopportunity', currentUser.uid, "ux")
    const techCOL = collection(database, 'startupopportunity', currentUser.uid, "tech")


   
      const pitchStoryDoc = await getDocs(pitchCOL);
      const uxStoryDoc = await getDocs(uxCOL);
      const techDoc = await getDocs(techCOL);


      if(pitchStoryDoc.docs.length > 0 && uxStoryDoc.docs.length > 0  && techDoc.docs.length> 0){
      setStartupOpportunity(true);
      }
      
    }
    
   

      getStartupIdentity();
      getOneLiner();
      getFounderInfo();
      getCaptableInfo();
      getinvestmnetRoundInfo();
      getInvestorDeck();
      getIntroDeck();
      getFinancialModel();
      getCapitalAllocation();
      getPitchStoryInfo();
      getStartupIdentity();
      getStartupOpportunityInfo();
      




 
  },[currentUser.uid,fetchUser,founderInformation,identity,oneLiner,captable,investmentRound])



  return (

    <>
      <div >
        <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", flexDirection: "row" }}>
          <PageHeadingLabel headingText={"Hello " + username} />

        
          {/* {!artifacts ? <Button onClick={() => {
            setArtifacts(true);
          }} variant="outlined" sx={{ textTransform: "none", borderRadius: "5px", borderColor: "black", color: "black", fontFamily: 'dmsans', fontSize: '0.9rem' }} startIcon={<CloudUploadOutlined />}> Artifacts section</Button> : null} */}

        </div>
        <div style={{display:"grid",}}>
        {/* <div>
          <SideNavRight visbile={artifacts} onClose={() => { setArtifacts(false) }} />

        </div> */}
        <div>
          <PageHeadingLabel headingText={"Complete these steps to pitch your startup to investors"} />
          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><RocketLaunchIcon color="primary" /></Box>
                
                <div style={{marginLeft:"1rem", fontFamily: "boldsans",justifyContent:"center",alignItems:"center",display:"flex" }}>Complete the information of your startup profile.</div>
              </div>
              <div style={{ color: "green", paddingRight: "1rem" }}>all completed</div>
            </div>

            <LinearProgress color='success' variant="determinate" value={22}/>
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>
              <Link style={{ textDecoration: 'none', color: 'black', }} to={pathList[0]} >
                      <ListItem key={pathList[0]}>
                        <ListItemButton >
                          <ListItemIcon >
                         
                         {identity ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{1}</span></div></div>} 
                          
                          </ListItemIcon>
                     
                          <ListItemText primary={dataList[0]} />
                          <ListItemIcon >
                            <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                          </ListItemIcon>
                        </ListItemButton>

                      </ListItem>
                      </Link>
                      <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                      <Link style={{ textDecoration: 'none', color: 'black', }} to={pathList[1]} >
                      <ListItem key={pathList[1]}>
                        <ListItemButton >
                          <ListItemIcon >
                         
                         {oneLiner ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{2}</span></div></div>} 
                          
                          </ListItemIcon>
                     
                          <ListItemText primary={dataList[1]} />
                          <ListItemIcon >
                            <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                          </ListItemIcon>
                        </ListItemButton>

                      </ListItem>
                      </Link>
                      <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />  
                      <Link style={{ textDecoration: 'none', color: 'black', }} to={pathList[2]} >
                      <ListItem key={pathList[2]}>
                        <ListItemButton >
                          <ListItemIcon >
                         
                         {founderInformation ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{3}</span></div></div>} 
                          
                          </ListItemIcon>
                     
                          <ListItemText primary={dataList[2]} />
                          <ListItemIcon >
                            <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                          </ListItemIcon>
                        </ListItemButton>

                      </ListItem>
                      </Link>
                      </List>
                      </div>
        
          </Box>

          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
             
                <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><MonitorIcon color="primary" /></Box>

                <div style={{ fontFamily: "boldsans",marginLeft:"1rem",justifyContent:"center",alignItems:"center",display:"flex" }}>Captable calculator.</div>
              </div>
              <div style={{ color:captable ? "green" : "black", paddingRight: "1rem" }}>{captable ?"all completed":"not completed" }</div>
            </div>

            <LinearProgress color='success' variant="determinate" />
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>

                <div>
                  <Link style={{ textDecoration: 'none', color: 'black', }} to={"/captable"} >
                    <ListItem>
                      <ListItemButton >
                        <ListItemIcon >
                        {captable ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{3}</span></div></div>} 
                                                   </ListItemIcon>
                        <ListItemText primary={"Add captable data"} />
                        <ListItemIcon >
                          <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                        </ListItemIcon>
                      </ListItemButton>

                    </ListItem></Link>
                </div>


              </List></div>

          </Box>


          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                
                <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><MoneyIcon color="primary" /></Box>

                <div style={{ fontFamily: "boldsans",marginLeft:"1rem",justifyContent:"center",alignItems:"center",display:"flex" }}>Complete the information of the investment round</div>
              </div>
              <div style={{ color: "green", paddingRight: "1rem" }}>all completed</div>
            </div>

            <LinearProgress color='success' variant="determinate" />
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>
              <Link style={{ textDecoration: 'none', color: 'black', }} to={"/investmentround"} >
                        <ListItem key={"Define the money you want to raise"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {investmentRound ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{1}</span></div></div>} 
                                                      </ListItemIcon>
                            <ListItemText primary={"Define the money you want to raise"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/investmentround"} >
                        <ListItem key={"Pre money valuation"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {investmentRound ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{2}</span></div></div>} 
                          
                            </ListItemIcon>
                            <ListItemText primary={"Pre money valuation"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/investmentround"} >
                        <ListItem key={"Post money valuation"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {investmentRound ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{3}</span></div></div>} 
                                                      </ListItemIcon>
                            <ListItemText primary={"Post money valuation"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/investmentround"} >
                        <ListItem key={"Define if you want to raise with SAFe or equity round"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {investmentRound ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{4}</span></div></div>} 
                                                      </ListItemIcon>
                            <ListItemText primary={"Define if you want to raise with SAFe or equity round"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        </List>
                        </div>

          </Box>
        
          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                
                 <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><MonitorIcon color="primary" /></Box>

                <div style={{ fontFamily: "boldsans",marginLeft:"1rem",justifyContent:"center",alignItems:"center",display:"flex" }}>Pitch Story.</div>
              </div>
              <div style={{color:pitchStory ? "green" :"black", paddingRight: "1rem" }}>{pitchStory ? "all completed" : "not completed"}</div>
            </div>

            <LinearProgress color='success' variant="determinate" value={pitchStory ? 100 : 0} />
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>

                <div>
                  <Link style={{ textDecoration: 'none', color: 'black', }} to={"/pitchstory"} >
                    <ListItem>
                      <ListItemButton >
                        <ListItemIcon >
                        {pitchStory ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{4}</span></div></div>}                         </ListItemIcon>
                        <ListItemText primary={"Upload Pitch data"} />
                        <ListItemIcon >
                          <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                        </ListItemIcon>
                      </ListItemButton>

                    </ListItem></Link>
                </div>


              </List></div>

          </Box>
          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
              
                <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><SignalCellularAltIcon color="primary" /></Box>

                <div style={{ fontFamily: "boldsans",marginLeft:"1rem",justifyContent:"center",alignItems:"center",display:"flex" }}>Data Room</div>
              </div>
              <div style={{ paddingRight: "1rem" }}>two steps missing</div>
            </div>

            <LinearProgress color='success' variant="determinate" value={70} />
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>
              <Link style={{ textDecoration: 'none', color: 'black', }} to={"/dataroom/Investor deck"} >
                        <ListItem key={"Investor deck"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {investorDeck ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{1}</span></div></div>} 
                          
                            </ListItemIcon>
                            <ListItemText primary={"Investor deck"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>

                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/dataroom/Intro deck"} >
                        <ListItem key={"Intro deck"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {introDeck ?  <CheckCircle  sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{2}</span></div></div>} 
                          
                            </ListItemIcon>
                            <ListItemText primary={"Intro deck"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/dataroom/Financial model"} >
                        <ListItem key={"Financial model"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {financialModel ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{3}</span></div></div>} 
                          
                            </ListItemIcon>
                            <ListItemText primary={"Financial model"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        <Divider sx={{ color: "rgba(28, 28, 28, 0.25)" }} />
                        <Link style={{ textDecoration: 'none', color: 'black', }} to={"/dataroom/Capital allocation"} >
                        <ListItem key={"Capital allocation"}>
                          <ListItemButton >
                            <ListItemIcon >
                            {capitalAllocation ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{4}</span></div></div>} 
                          
                            </ListItemIcon>
                            <ListItemText primary={"Capital allocation"} />
                            <ListItemIcon >
                              <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                            </ListItemIcon>
                          </ListItemButton>

                        </ListItem>
                        </Link>
                        </List>
                        </div>
              

          </Box>
          <br />
          <Box sx={{
            borderRadius: "10px", borderColor: "black",
            border: "1px solid var(--black-20, rgba(28, 28, 28, 0.20))"
          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.5rem" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
             
                <Box sx={{borderRadius:"10px",width:"48px",height:"44px",justifyContent:"center",alignItems:"center",display:"flex",background:"#DEEAFD"}}><MonitorIcon color="primary" /></Box>

                <div style={{ fontFamily: "boldsans",marginLeft:"1rem",justifyContent:"center",alignItems:"center",display:"flex" }}>Startup opportunity</div>
              </div>
              <div style={{ paddingRight: "1rem",color:startupOpportunity ? "green" : "black" }}>{startupOpportunity ? "all completed":"not completed" }</div>
            </div>

            <LinearProgress color='success' variant="determinate" value={startupOpportunity ? 100 : 0} />
            <div style={{ width: isMobile ? '100%' : '98%' }}>
              <List disablePadding>

                <div>
                  <Link style={{ textDecoration: 'none', color: 'black', }} to={"/startupopportunity"} >
                    <ListItem>
                      <ListItemButton >
                        <ListItemIcon >
                        {startupOpportunity ?  <CheckCircle sx={{color:"#257E4C"}} /> : 
                         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> <div className="circle"><span style={{padding:"0.3rem",fontSize:"1rem"}}>{4}</span></div></div>} 

                        </ListItemIcon>
                        <ListItemText primary={"Upload Startup data"} />
                        <ListItemIcon >
                          <NavigateNextIcon sx={{ marginLeft: "40px" }} />
                        </ListItemIcon>
                      </ListItemButton>

                    </ListItem></Link>
                </div>


              </List></div>

          </Box>
        </div>
        </div>
      
      </div>
    </>
  );
};

export default OnBoarding;
