import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from 'react-responsive';
import { doc, getDoc, updateDoc, setDoc, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { increment } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { storage, database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext';



const CaptableFormNew = ({ open, onClose, totalEquity, sharedholderName, sharedholderEquity, docID }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const min = 0;

  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [pastEquityForRestoration, setpastEquityForRestoration] = useState("");

  const { currentUser } = useAuth();
  const [documentID, setDocumentID] = useState('');



  function posToNeg(num) {
    return -Math.abs(num);
  }

  const updateDocEquity = async (totalEquity, equity, docID) => {


    var newEquity = parseInt(equity, 10) || '';

    console.log("pastEquity " + sharedholderEquity);
    console.log("newEquity " + newEquity);

    console.log("sharedholderEquity===newEquity " + newEquity.length);



    const docEquity = doc(database, "captable", currentUser.uid);
    const docE = await getDoc(docEquity);



    if (docID) {

      if (totalEquity == 0 && newEquity < sharedholderEquity) {
        const remainingEquity = sharedholderEquity - newEquity;
        await updateDoc(docEquity, {
          totalEquity: increment(posToNeg(remainingEquity))
        })

      } else if(newEquity.length===0)  {
        await setDoc(docEquity, {
          totalEquity: sharedholderEquity
        })
      }
      else  {
        await setDoc(docEquity, {
          totalEquity: increment(newEquity)
        })
      }

    } else {
      if (docE.exists()) {
        await updateDoc(docEquity, {
          totalEquity: increment(newEquity),
        });
      } else {
        await setDoc(docEquity, {
          totalEquity: increment(newEquity),
        });
      }





    }
  };


  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setFileName(URL.createObjectURL(selectedFile));
    }
  };


  useEffect(() => {

    setValue("name", sharedholderName || "");
    setValue("equity", sharedholderEquity || "");

    setDocumentID(docID);
    if (totalEquity == 0) {
      setValue("totalEquity", sharedholderEquity || "");
    } else {
      const eq = totalEquity + sharedholderEquity;

      setValue("totalEquity", eq || "");


    }
  }, [totalEquity, sharedholderName, sharedholderEquity, docID, setValue])


  const onSubmit = async (data) => {
    await handleFileUploadToFirebaseStorage(data.name, data.equity)
  };

  const handleFileUploadToFirebaseStorage = async (name, equity) => {

    if (file) {
      setDisableUploadButton(true);
      const storageRef = ref(storage, `users/${currentUser.email}/captable/${file.name}_${uuidv4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileUploadProgress(progress);
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'success':
              console.log('Upload is completed');
              break;
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              console.log('Nothing');
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              console.log('Nothing');
          }
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);
          await updateDocEquity(equity);

          await addDoc(collection(database, "captable", currentUser.uid, "shareholders"), {
            sharedholderName: name,
            equity: equity,
            photoURL: downloadURL,
            createdAt: new Date(),
          });

          setShowUploadMessage(true);
          setDisableUploadButton(false);
        }
      );
    } else {
      console.error('No file selected');

      if (documentID) {
        await updateDocEquity(totalEquity, equity, documentID);

        await updateDoc(doc(database, "captable", currentUser.uid, "shareholders", documentID), {
          sharedholderName: name,
          equity: equity,
          updatedAt: new Date(),
        })
      }
      else {
        await updateDocEquity(totalEquity, equity);

        await addDoc(collection(database, "captable", currentUser.uid, "shareholders"), {
          sharedholderName: name,
          equity: equity,
          photoURL: "",
          createdAt: new Date(),
        })
      }

      setShowUploadMessage(true);
      setDisableUploadButton(false);
    }

    reset();
    onClose();
  };


  const handleEquityChange = (e) => {
    // Custom logic for equity TextField


    let equity = parseInt(e.target.value, 10) || '';
    if (equity > totalEquity) equity = totalEquity;
    if (equity < min) equity = min;
    setValue('equity', equity);

    console.log("equity " + equity);
    console.log("totalEquity " + totalEquity);
  };

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };



  return (

    <Dialog
      PaperProps={{ sx: { borderRadius: "10px" } }}

      onClose={onClose} open={open} fullWidth maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{docID ? "Update Shareholder" : "Add New Shareholder"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>

          <FormControl fullWidth>
            <Stack spacing={4} sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'start' }}>
                <FormGroup>
                  <FormLabel>Shareholder Photo</FormLabel>
                  <div className="user-image">
                    <img onClick={handleClick} className="user-profile-pic" src={fileName || process.env.PUBLIC_URL + '/pic/user_profile.png'} alt="shareholder" />
                    <input
                      type="file"
                      onChange={handleChange}
                      ref={hiddenFileInput}
                      style={{ display: "none" }} />
                    <span>{isMobile ? "Upload Photo" : "Upload shareholder photo"}</span>
                  </div>
                </FormGroup>
              </Box>

              <FormGroup >
                <FormLabel sx={{
                  color: " #1C1C1C",
                  fontFamily: "boldsans",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}>Shareholder Name</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
                {errors.name && <p className="error-text">{errors.name.message}</p>}
              </FormGroup>

              <FormGroup>
                <FormLabel sx={{
                  color: " #1C1C1C",
                  fontFamily: "boldsans",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}>Equity</FormLabel>
                <Controller
                  name="equity"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Equity is required',

                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      placeholder="25%"
                      fullWidth
                      inputProps={{ min: min, max: totalEquity }}
                      onChange={(e) => {
                        handleEquityChange(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors.equity && <p className="error-text">{errors.equity.message}</p>}
              </FormGroup>
            </Stack>
          </FormControl>

        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button type="submit" variant="outlined" sx={{ marginLeft: "2rem", marginTop: "2rem", marginBottom: "2rem", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} autoFocus  >
            {docID ? "Update" : "Add"}
          </Button>
          <div className='upload-file-msg'>Saved</div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaptableFormNew;
