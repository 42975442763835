import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMediaQuery } from 'react-responsive';

const ListComponent = ({dataList,pathList}) => {

  
        const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
        
  return (
 
    <div style={{borderRadius: '10px',
    border: "1px solid rgba(28, 28, 28, 0.20)",width:isMobile ?'100%' :'98%'}}>
      <List>
                    {dataList.map((item, index) => (
                        <div>
                            <Link style={{ textDecoration: 'none', color: 'black', }} to={pathList[index]} >
                                <ListItem key={index}>
                                    <ListItemButton >

                                        <ListItemText primary={item} />
                                        <ListItemIcon >
                                            <NavigateNextIcon sx={{marginLeft:"40px"}} />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem></Link>
                              {index < dataList.length - 1 &&  <Divider sx={{color:"rgba(28, 28, 28, 0.25)"}}/> } </div>

                    ))}
                </List></div>                                                                                                   
   
  
  
  )
}

export default ListComponent;