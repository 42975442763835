import React, { useCallback, useEffect, useState } from 'react'
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import Investment from "../../assets/investment.png";
import "./InvestmentPipeline.css"
import InvestmentTable from './InvestmentTable';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../hooks/AuthContext';
import { database } from '../../firebase';


export default function InvestmentPipeline() {
  const [applicationStatus, setApplicationStatus] = useState('');
  const { currentUser } = useAuth();


  const fetchApplicationStatus = useCallback(async () => {
    const docRef =doc(database, 'findInvestors', currentUser.uid)

    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
       
        setApplicationStatus(doc.data().status);

      } 
    })

  }, [currentUser.uid]);



  useEffect(() => {
    fetchApplicationStatus();
  }, [applicationStatus,fetchApplicationStatus])


  return (
    <div>
      <PageHeadingLabel headingText={"Investment Pipeline"} />

  {applicationStatus ==="pending" ? 
  <div> <img src={Investment} style={{ maxWidth:"60%",maxHeight:"60%",paddingRight:"2rem"}} ></img>
    <div id='app-receive'>Application Received</div>
    <div id='app-receive-info'>Your application to join Assisted Fundraising has been received.<br/>A member of the Dozero team will review your documentation, and will reach out in the next 2 - 3 business days.</div>
    <br/></div> : null }
    {applicationStatus ==="approved" ?    <InvestmentTable/> : null}
    {/* <TemporaryDrawer/> */}
    </div>
  )
}
