import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import PageTopComponent from "../../../component/PageTopComponent/PageTopComponent"
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useMediaQuery } from 'react-responsive';
import FounderForm from './FounderForm';
import { useForm } from 'react-hook-form';
import { useCollection } from 'react-firebase-hooks/firestore';
import {  updateDoc, increment, deleteDoc, doc, collection, onSnapshot } from "firebase/firestore";
import { database } from '../../../firebase';
import { useAuth } from '../../../hooks/AuthContext';
import DeleteFileDialog from '../../../component/DeleteFileDialog';



export default function Founder() {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const [closeDialog, setCloseDialog] = useState(false);
  const [closeDialogForUpdate, setCloseDialogForUpdate] = useState(false);
  const [documentID, setDocumentID] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);


  const handleDialogClose = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }
    setCloseDialog((closeDialog) => !closeDialog);

  };

  async function deleteDocument(docID) {
    console.log("docID " + docID);
    const docRef = doc(database, "startupInformation", currentUser.uid, "founders", docID);
;

    await deleteDoc(docRef);

  }


  const deleteItemhandle = (docID) => {
 
    setDocumentID(docID)
    setDialogOpen(true);
  };
  const handleDialogCloseForUpdate = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }
    setCloseDialogForUpdate((closeDialogForUpdate) => !closeDialogForUpdate);

  };


  let myRef = collection(database, "startupInformation", currentUser.uid, "founders") 


  const [value, loading, error] = useCollection(
    myRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <>
      <PageTopComponent title={"Founders"} buttonText={isMobile ? "Add" : "Add new Founder"} onClick={handleDialogClose} />
      {/* <FounderForm onClose={handleDialogCloseForUpdate} open={closeDialogForUpdate} editable={false} name={"inzmam"} role={"CTO"} imageUrl={""}
    linkd={"dsadsd"} /> */}


      <br />

      {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Checking data...</span>}
        {value && (
          <span>
            {value.docs.map((docData) => (
              <React.Fragment key={docData.id}>
                
                <div style={{ paddingLeft: "15px", paddingRight: "25px", height: "80px", border: "1px solid #1C1C1C", borderRadius: "10px", display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={{ display: "inline", alignItems: "center", justifyContent: "center" }}>
            <img

              style={{ display: "inline", marginRight: "1rem", maxWidth: "47px", maxHeight: "47px" }}
              className="user-profile-pic"

              src={docData.data().profileImageUrl ?docData.data().profileImageUrl : process.env.PUBLIC_URL + "/pic/user_profile.png"}
              alt="profile" />

          </div>

          <div> <p className='title-text'>{docData.data().founderName + " - " + docData.data().positionInCompany }</p>
            <Link className='data-date' to={docData.data().likedinProfileUrl} target='_blank'>LinkedIn</Link></div>
        </div>
        <div><IconButton onClick={handleDialogCloseForUpdate} > <EditIcon sx={{ color: "black", border: "1px solid black", borderRadius: "5px" }} /></IconButton>
          <IconButton onClick={()=>deleteItemhandle(docData.id)}  ><Delete sx={{ color: "#FB9B9B", border: "1px solid #FB9B9B", borderRadius: "5px" }} /></IconButton></div>

      </div>

              </React.Fragment>

            ))}
          </span>
        )}
<DeleteFileDialog
                  deleteText={"Are you sure you want to delete this file? This action cannot be undone."}
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  onDelete={() => deleteDocument(documentID)}
                />
<FounderForm onClose={handleDialogClose} open={closeDialog} />
  
    </>

  )
}
