import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import { useStripeSubscription } from '../../hooks/useStripeSubscription';

const   PrivateRoute = ({Component}) => {
  const { currentUser } = useAuth();
  const { subscription } = useStripeSubscription();


  if(subscription){
    console.log("PrivateRoute:=====> subscription " + subscription.status );
    return currentUser  ? (subscription.status==='active' || subscription.status==='trialing'  ? <Component /> :<Navigate to="/memberships" replace={true} /> ) : <Navigate to="/login" replace={true} />

  }else{
    return currentUser  ?  <Component /> : <Navigate to="/login" replace={true} />

  }

}

export default PrivateRoute;