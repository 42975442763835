import { Button } from '@mui/material'
import React, { useState } from 'react'
import './TitleAndBackButton.css'
import UploadFileDialog from '../UploadFileDialog/UploadFileDialog';
import FileList from '../FileList/FileList';
import LabelBackButton from '../../component/LabelBackButton';



export default function TitleAndBackButton(props) {

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenUploadDialog((openUploadDialog) => !openUploadDialog)
  };


  return (

    <>
      <div className='data-upload'>
       <LabelBackButton title={props.title} />
        <Button variant='outlined' onClick={handleClickOpen} sx={{ textTransform: "none", borderRadius: "5px", borderColor: "black", color: "black", fontFamily: 'dmsans', fontSize: '0.9rem' }}>{props.buttonText}</Button>
      </div>
      <UploadFileDialog open={openUploadDialog} onClose={handleClickOpen} title={props.dialogTitle} fileTitle={props.fileTitle} fileType={props.fileType}/>
      <br />



      <FileList colName={props.dialogTitle}/>


    </>
  )
}

