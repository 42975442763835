import React from 'react'
import LabelBackButton from '../LabelBackButton'
import "./EmailBlurpContent.css"
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function EmailBlurpContent(props) {


  return (
    <div>
      <LabelBackButton title={props.title} />

      {(() => {

        if (props.title === "Ask for VC Intro") {
          return <AskForIntro />
        }
        else if (props.title === "Cold email to founders") {
          return <ColdEmailForFounders />;
        } else if (props.title === "Cold email to investors") {
          return <ColdEmailForInvestors />
        } else if (props.title === "Follow-up after intro") {
          return <FollowUpAfterIntro />
        }
      })()}


    </div>
  )
}



function AskForIntro() {
  return (
    <Stack >
      <Stack spacing={2} sx={{ width: "70%",marginTop:"2rem",marginLeft:"2rem"} }>
<h4>👉 Ask for intro</h4>
  <p>
  Hello Dylan, are you comfortable making an intro to Roelof? I was looking at the startups he invested in and I think he would be a great fit as a partner for AutoXAI.

  </p>


</Stack>
<div style={{ display: "flex", flexDirection: "row", marginTop: "4rem" }}>


<div className='vl'></div>

<Stack spacing={2} sx={{ width: "70%",marginLeft:"2rem" }}>


  <h4> 👉 The possible intro with you in CC:</h4>
  <p>Hey Roelof,</p>
  <p>I would like to introduce you to Elon, co-founder of AutoXAI, an autonomous AI that helps small businesses gather qualified leads online. Elon and his co-founders have been bootstrapping the company. They have a product and paying customers and they are now raising 200k in SAFE notes at a 2M cap. I met Elon while he worked as a venture capitalist and I am excited about what he is building within the autonomous AI industry.
  </p>
  <p>I am sure this email will be the start of an interesting conversation between you two guys!
  </p>
  <p>I leave you to connect and remain at your full disposal, Dylan
  </p>
</Stack>
</div>
    </Stack>
    
  )
}


function FollowUpAfterIntro() {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: "4rem" }}>
      <div className='vl'></div>
      <Stack spacing={2} sx={{ width: "70%", marginLeft: "2rem" }}>
        <h4>👉 Follow-up after intro</h4>
        <p>Hello Roelof,
        </p>
        <p>I hope you are doing great and excited about the Age of A.I.
          It would be a pleasure to meet and pitch you AutoXAI, you can choose the best timing for you in my calendly below:
          <Link to={"https://calendly.com/autoxai/30minscall"}>calendly</Link></p>

        <p>I'm sending you an attached deck and also a brief description below:</p>

        <p>Description
          AutoXAI is an AI-powered assistant that turns simple forms into lead-generation campaigns for small businesses. With AutoXAI, small business owners can have an autonomous worker that bring clients for them so they can go back and focus on offering the best service for their existing clients.
        </p>

        <p>Key Product Metrics (28/02/2023)
        </p>
        <ul>
          <li>Registered users: 500</li>
          <li>AVG Weekly growth of users: 100%
          </li>
          <li>Total Revenue: 250€</li>
          <li>Total Revenue Churn:
          </li>
          <li>Organic Growth: 100%</li>
        </ul>


        <p>Goals for 2023
        </p>
        <ul>
          <li>200K active users</li>
          <li>500K activities completed
          </li>
          <li>70k MRR</li>
          <li>15% of total Portuguese students registered
          </li>
          <li>8% of total Spanish students registered</li>
        </ul>

        <p>Fundraising</p>
        <p>We are raising x SAFE notes at yM cap to execute z months to reach k MRR. We want to raise a priced pre-seed round of 1M at 6M Pre-money valuation, with the objective of expanding our operations either to Brazil or USA and consolidating our product to attack other verticals. With the co-founders’ initial investment, we have 6 months of runway and we successfully build the MVP and got our first clients.
        </p>
      </Stack>
    </div>
  )
}



function ColdEmailForInvestors() {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: "4rem" }}>
      <div className='vl'></div>
      <Stack spacing={2} sx={{ marginLeft: "2rem" }}>
        <h4> 👉 Cold email to investors</h4>
        <p>Hello Roelof,</p>
        <Box sx={{ width: "70%" }}>
          <Stack spacing={2}>
            <p id="myInput">I am Elon, a previous VC and now co-founder of AutoXAI.AutoXAI is an autonomous AI that helps small businesses gather qualified leads online. The AutoGPT experiment has brought this industry into focus lately. We have been bootstrapping the company. We have a product and paying customers and we are starting to raise now.
              I have been seeing that you have been actively fundraising
              so I would love to get in touch with you so we can share experiences and contacts.</p>
            <p>What do you think? If it is easier for you, you can choose the best time for you in my calendly below:<Link to={"https://calendly.com/autoxai/30minscall"}>calendly</Link> 
            </p>
          </Stack>

        </Box>

      </Stack>

    </div>
  )
}



function ColdEmailForFounders() {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginTop: "4rem" }}>
      <div className='vl'></div>
      <Stack spacing={2} sx={{ marginLeft: "2rem" }}>
        <h4> 👉 Cold email to founders</h4>
        <p>Hello Founder,</p>
        <Box sx={{ width: "70%" }}>
          <Stack spacing={2}>
            <p>I am Elon, a previous VC and now co-founder of AutoXAI.AutoXAI is an autonomous AI that helps small businesses gather qualified leads online. The AutoGPT experiment has brought this industry into focus lately. We have been bootstrapping the company. We have a product and paying customers and we are starting to raise now.
              I have been seeing that you have been actively fundraising
              so I would love to get in touch with you so we can share experiences and contacts.</p>
            <p>What do you think? If it is easier for you, you can choose the best time for you in my calendly below: <Link to={"https://calendly.com/autoxai/30minscall"}>calendly</Link>
            </p>
          </Stack>

        </Box>

      </Stack>

    </div>
  )
}