import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../../../hooks/AuthContext';
import { updateDoc, doc, collection, addDoc, getDoc, } from "firebase/firestore";
import { auth, database } from '../../../firebase';
import { ref, uploadBytesResumable,getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

export default function FounderForm({ open, onClose }) {
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme => theme.spacing(4),
        },
        '& .MuiDialogActions-root': {
            padding: theme => theme.spacing(1),
        },
    }));


    const {handleSubmit, control,reset, formState: { errors } } = useForm();

    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState('');
    const [downloadURL, setDonwloadURL] = useState('');

    const { currentUser } = useAuth();

    const hiddenFileInput = useRef(null);



    function isValidLinkedInProfile(url) {
        // Regular expression for a basic LinkedIn profile URL
        const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
      
        return linkedInRegex.test(url);
      }


    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

     const handleFileUploadToFirebaseStorage = async (data) => {
    let compressedFile;
    if (file) {
      // Compress the file
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      try {
        compressedFile = await imageCompression(file, options);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }

    if (compressedFile) {
      // Create a storage reference to the file
      const filePath = `users/${currentUser.email}/startupInformation/founders/${file.name}_${uuidv4()}`;
      const storageRef = ref(storage, filePath);

      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      try {
        // Upload completed successfully, now we can get the download URL
        const url = await getDownloadURL(uploadTask.snapshot.ref);

        await addDoc(collection(database, "startupInformation", currentUser.uid, "founders"), {
          founderName: data.shareholderName,
          positionInCompany: data.pos,
          profileImageUrl: url,
          likedinProfileUrl: data.linkedin,
          createdAt: new Date(),
        });

        setDonwloadURL(url);
      } catch (error) {
        console.error('Error getting download URL:', error);
      }
      finally{
        reset();
        onClose();
      }
    } else {
      // Handle the case where no file is selected
      console.error('No file selected');
      await addDoc(collection(database, "startupInformation", currentUser.uid, "founders"), {
        founderName: data.shareholderName,
        positionInCompany: data.pos,
        profileImageUrl: "",
        likedinProfileUrl: data.linkedin,
        createdAt: new Date(),
      });
    }
  };

  //    const handleFileUploadToFirebaseStorage = async(data) => {
  //   if (file) {

  //     // Create a storage reference to the file
  //     const filePath = `users/${currentUser.email}/startupInformation/founders/${file.name}_${uuidv4()}`;
  //     const storageRef = ref(storage, filePath);

  //     let compressedFile;
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920
  //     }
  //     try {
  //        compressedFile = await imageCompression(file, options);

  //     } catch (error) {
  //       console.error('Error compressing image:', error);
  //     }
  //     const uploadTask = uploadBytesResumable(storageRef, compressedFile,);

  //     // Listen for state changes, errors, and completion of the upload.
  //     uploadTask.on('state_changed',
  //       (snapshot) => {
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

  //         //  setFileUploadProgress(progress);

  //         console.log('Upload is ' + progress + '% done');
  //         switch (snapshot.state) {
  //           case 'success':

  //             console.log('Upload is completed');
  //             break;
  //           case 'paused':
  //             console.log('Upload is paused');
  //             break;
  //           case 'running':
  //             console.log('Upload is running');
  //             break;
  //           default:
  //             console.log("nothn")
  //         }
  //       },
  //       (error) => {
  //         // A full list of error codes is available at
  //         // https://firebase.google.com/docs/storage/web/handle-errors
  //         switch (error.code) {
  //           case 'storage/unauthorized':
  //             // User doesn't have permission to access the object
  //             break;
  //           case 'storage/canceled':
  //             // User canceled the upload
  //             break;



  //           case 'storage/unknown':
  //             // Unknown error occurred, inspect error.serverResponse
  //             break;
  //           default:
  //             console.log("noth");
  //         }
  //       },
  //       async () => {
  //         // Upload completed successfully, now we can get the download URL
  //         getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
  //           console.log('File available at', url);

  //           await addDoc(collection(database, "startupInformation", currentUser.uid, "founders"), {
  //             founderName: data.shareholderName,
  //             positionInCompany: data.pos,
  //             profileImageUrl: url,
  //             likedinProfileUrl: data.linkedin,
  //             createdAt: new Date()
  //         });
  //           setDonwloadURL(url);

  //         });



  //       }

  //     );


  //   } else {
  //     console.error('No file selected');
  //     await addDoc(collection(database, "startupInformation", currentUser.uid, "founders"), {
  //       founderName: data.shareholderName,
  //       positionInCompany: data.pos,
  //       profileImageUrl: "",
  //       likedinProfileUrl: data.linkedin,
  //       createdAt: new Date()
  //   });
  //   }

  // };




    const onSubmit = async (data) => {
          console.log("data  "+data)
        await handleFileUploadToFirebaseStorage(data);
    
  


    };






  // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        setFileURL(URL.createObjectURL(fileUploaded));
        setFile(fileUploaded);
    };







    return (

              
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick
                fullWidth
                borderRadius="10px"
                maxWidth="xs"
            >
                <DialogTitle sx={{ marginLeft: 2, p: 2 }} id="customized-dialog-title">
                    {"Add new Founder"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <form onSubmit={handleSubmit(onSubmit)}>

                <FormControl  fullWidth >

                <DialogContent>

                    Founder Photo
                    <div className="user-image">
                        <img
                            onClick={handleClick}
                            className="user-profile-pic"
                            src={fileURL ? fileURL : process.env.PUBLIC_URL + "/pic/user_profile.png"}
                            alt="profile"

                        />


                        <span>Upload founder photo</span>
                    </div>
                    <input
                    type="file"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }} />

                </DialogContent>
                <DialogContent>
                    <FormGroup>
                        <FormLabel>{"Founder Name"}</FormLabel>

                        <Controller
                            name="shareholderName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Name is required' }}
                            render={({ field }) => <TextField {...field} fullWidth />}
                        />

                        {errors.shareholderName && <p className="error-text">{errors.shareholderName.message}</p>}

                    </FormGroup>

                </DialogContent>
                <DialogContent>
                    <FormGroup>


                        <FormLabel>{"LinkedIn Profile"}</FormLabel>

                        <Controller
                            name="linkedin"
                            control={control}

                            defaultValue=""
                            rules={{ required: 'LinkedIn Url is required',validate:isValidLinkedInProfile }}
                            render={({ field }) => <TextField {...field} fullWidth placeholder='https://www.linkedin.com/in/inzikhan' />}
                        />
                        {errors.linkedin && <p className="error-text">{errors.linkedin.message}</p>}

                    </FormGroup>


                </DialogContent>
                <DialogContent>
                    <FormGroup>


                        <FormLabel>{"Position in the company"}</FormLabel>

                        <Controller
                            name="pos"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Role  is required' }}
                            render={({ field }) => <TextField {...field} fullWidth placeholder='ex: Co-Founder and CPO' />}
                        />

                        {errors.pos && <p className="error-text">{errors.pos.message}</p>}


                    </FormGroup>

                </DialogContent>

                    </FormControl>
                    <Button type='submit' disabled={false} sx={{ marginLeft: "20px", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} variant='outlined' 

>
 Add
</Button>
</form>
                
              
            </BootstrapDialog>
         

      
    )
}
