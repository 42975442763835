import ListComponent from '../../component/List/ListComponent'
import "./startup.css"
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
  
const Startup = () => {
  const dataList = [
    '3 minutes Pitch (Video)',
    '3 minutes on UX/UI (Video)',
    '3 minutes on Tech (Video)',
];
const pathList = [
    '3 minutes Pitch (Video)',
    '3 minutes on UX (Video)',
    '3 minutes on Tech (Video)'
];



  return (
    <div className='mobile-friendly'>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",marginRight:"1.5rem"}}>
      <PageHeadingLabel headingText={"Startup Opportunity"}/>

   
      </div>
  
      <br/>

     
      <ListComponent pathList={pathList} dataList={dataList} />

   


   
      
      </div>
  
  )
}

export default Startup