import './membership.css'
import PageHeadingLabel from "../../component/PageHeadingLabel/PageHeadingLabel"
import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import { createCheckoutSession, onCurrentUserSubscriptionUpdate } from "@invertase/firestore-stripe-payments";
import firebaseApp, { payments } from "../../firebase";
import { getProducts } from "@invertase/firestore-stripe-payments";
import { getFunctions, httpsCallable } from "firebase/functions";
import styled from '@emotion/styled';
import { ShoppingBag } from '@mui/icons-material';
import ReviewCard from '../../component/Review/ReviewCard';
import { useAuth } from '../../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useStripeSubscription } from '../../hooks/useStripeSubscription';



const Memberships = () => {
    const [buttonText,setButtonText] = useState("Checkout");
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [disableButton,setDisableButton] = useState(false);





    


  async function createStripeCheckoutSession(){
   setDisableButton(true);

        try {
            await createCheckoutSession(payments, {
                price: "price_1OAVkuKk9ItqkXt8etBYToJF",
                
            }, {    }).then((e) => {
                console.log("createCheckoutSession " + e.url);
                window.location.assign(e.url);
               setDisableButton(false);

            })
        } catch (e) {
            console.log("createCheckoutSession error " + e);
           setDisableButton(false);

        }
    }


    async function createStripePortalLink(){
        setDisableButton(true);

        const functions = getFunctions();


        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');


        const { data } = await functionRef({
            returnUrl: window.location.origin,
            locale: "auto", // Optional, defaults to "auto"
            //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        });
        window.location.assign(data.url);

        setDisableButton(false);

    }

    useEffect(()=>{
        const subscriptionUpdateListener = onCurrentUserSubscriptionUpdate(
            payments,
            (snapshot) => {

                if(snapshot.empty){
                    navigate('/memberships', { replace: true });
                  }else{
                    for (const change of snapshot.changes) {
                      console.log("change.subscription.status " + change.subscription.status ==="trialing");
                      if (change.subscription.status === 'active' || change.subscription.status === 'trialing' ) {
                        navigate('/', { replace: true });
                      } else {
                        navigate('/memberships', { replace: true });
                      }
                    }
                  }
        
            }
          );

          return () => {
            subscriptionUpdateListener();
          };
    },[]);


    const lisitemSize = styled({
        customFontSize: {
            fontSize: '12px', // Adjust the font size as per your requirement
        },
    });
    const reviews = [
        { id: 1, text: 'Great service!', customerName: 'Alice' },
        { id: 2, text: 'Very satisfied with the product.', customerName: 'Bob' },
        { id: 3, text: 'Great service!', customerName: 'Alice' },
        { id: 4, text: 'Very satisfied with the product.', customerName: 'Bob' },


        // Add more review objects as needed
      ];


    const basicPlanFeatures = [
        'ONBOARDING',
        'STARTUP INFORMATION',
        'INVESTMENT ROUND',
        'PITCH STORY',
        'STARTUP OPPORTUNITY',
        'EMAIL BLURP',
        'LIMITED LEARN SECTION(LEARN VIDEOS, TEMPLATES, FRAMEWORKS AND EXAMPLES)',
        'KPIs',
        'DATA ROOM',
        'CAPTABLE',
        'EMAIL SUPPORT'
    ]

    const premiumfeatures = [
        `FIND INVESTORS (ASSISTED FUNDRAISING) -We will connect you with the best investors in the market that fit with your startup profile.
        - 3% success fee of the investment raised.`,
        "DOCUMENTATION REVIEW FROM AN DOZERO INVESTMENT ANALYST",
        "FULL ACCESS TO FRAMEWORKS AND TEMPLATES",
        "FULL ACCESS HOW-TO-DO IN VIDEO",
        "FULL ACCESS TO ADDITIONAL RESOURCES",
        `INCULDED ALL NEXT FEATURES
        - investors Updates"
        - Real Room
        - Document signing and more features`,
        "PRIORITY SUPPORT"
    ];

    const handleLogout = () => {

        signOut(auth).then(() => {
          // Sign-out successful.
          navigate("/",{replace:true});
          console.log("Signed out successfully")
        }).catch((error) => {
          // An error happened.
        });
      }
    


    return (
        <>
                    <Button onClick={handleLogout} sx={{textTransform:"none",textDecoration:"underline",color:"black",display:"flex",justifyContent:"end",flexDirection:"row",position:"fixed",right:0,paddingRight:"1rem"}}>Logout</Button>    

         <div style={{display: "flex",
        flexDirection:"column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh" }} >
            <PageHeadingLabel headingText={"Membership"} />
            <br />
            <Typography className="membership-subtext">Choose a plan to have access to find investors, document signing, deal room, investor updates and more.</Typography>
            <br />

            <div style={{ display: "flex", flexDirection: "row" }} >
                <Box sx={{
                    width: "40.5rem",

                    zIndex: "1",
                    height: "30rem", background: "#EFF0FF", borderRadius: "8px", border: "1px "
                }}>
                    <Stack spacing={2} sx={{marginLeft:"5rem",marginTop:"5rem"}}>
                        <Stack direction={"row"}><ShoppingBag sx={{ color: "#6A70EF" }} /> <Typography sx={{ fontFamily: "boldmont", fontSize: "1rem", color: "#6A70EF", textTransform: "uppercase" }}>
                            premium plan
                        </Typography></Stack>

                        <Typography sx={{
                            borderRadius: "0.1875rem",
                            width: "10%", padding: "5px",
                            fontFamily: "mont", fontSize: "0.8rem", color: "black", background: "#C7E9D9"
                        }}>
                            50% off
                        </Typography>
                        <Stack direction={"row"}>
                            <Typography sx={{ marginRight: "0.5rem", fontFamily: "boldmont", fontSize: "1.75rem", color: "#639", textDecoration: "line-through",marginTop:"0.2rem" }} >€120</Typography>
                            <Typography sx={{ fontFamily: "boldmont", fontSize: "2rem", color: "#639" }}>
                                €59,99
                                <span style={{ fontFamily: "boldmont", fontSize: "1rem", color: "rgba(102, 51, 153, 0.50)" }}>/MONTH
                                </span>
                            </Typography>
                        </Stack>


                        <Typography  sx={{
                           
                     
                            fontFamily: "mont", fontSize: "1.0625rem", color: "#639"
                        }}>
                            15 days free trial
                        </Typography>
                        <Typography sx={{
                           
                     
                           fontFamily: "dmsans", fontSize: "0.875", color: "#393C5C"
                       }}>
                            you will receive an email before<br/>
                            the free trial ends.<br/>
                            You can cancel anytime.
                        </Typography>
                       
                    </Stack>
                    <Button  disabled={disableButton} variant='outlined' onClick={async()=>{
                        if(buttonText === "active" || buttonText ==="trailing"){
                         await  createStripePortalLink();
                        }else{
                        await createStripeCheckoutSession();
                        
                        }
                    
                    }}
    
                    sx={{cursor:disableButton ? "not-allowed" :"default", marginLeft:"5rem",marginTop:"3rem",borderRadius:"8px",width:"20%",background:"#639",color:"white",fontFamily:"mont",fontSize:"0.875rem",textTransform:"none",padding:"7px"}} >{buttonText}</Button>
                </Box>

                <br />
               
                <Box sx={{
                    boxShadow: "0.015rem 0.015rem",
                    display: "flex",
                    padding:"2rem",
                    maxWidth:"34rem",
                    marginLeft: "30rem",
                    marginTop: "2rem",
                    zIndex: "2",
                    position: "absolute",
                    background: "#FFFFFF",
                    flexShrink: 0, borderRadius: "0.3125rem", border: "1px"
                }}>

                    <div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                            <List>
                                {basicPlanFeatures.map((item) => {
                                    return <ListItem  sx={{paddingTop:"0.2rem",paddingBottom:"0" }} >
                                        <CheckCircleOutlineIcon fontSize='small' style={{ marginRight: "12px" }} />
                                        <ListItemText primaryTypographyProps={{ fontSize: '0.6rem' }} primary={item} />
                                    </ListItem>
                                })}

                            </List>
                        </div>

                    </div>

                    <div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                            <List>
                                {premiumfeatures.map((item) => {
                                    return <ListItem  sx={{paddingTop:"0.2rem",paddingBottom:"0" }} >
                                        <CheckCircleOutlineIcon fontSize='small' style={{ marginRight: "12px" }} />
                                        <ListItemText primaryTypographyProps={{ fontSize: '0.6rem' }} primary={item} />
                                    </ListItem>
                                })}

                            </List>
                        </div>

                    </div>
                </Box>

            </div>

            {/* <div className="reviews-container">
      {reviews.map((review) => (
        <ReviewCard key={review.id} review={review} />
      ))}
    </div> */}
        </div></>
       
    )
}
export default Memberships