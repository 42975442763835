import React from 'react'
import { useParams } from 'react-router-dom';
import EmailBlurpContent from './EmailBlurpContent';

export default function EmailBlurp() {
    const param = useParams();
  return (
    <div>
      <EmailBlurpContent title={param.name} />
    </div>
  )
}
