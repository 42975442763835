import React, { useState } from 'react'
import PageTopComponent from "../../../component/PageTopComponent/PageTopComponent"
import "./Incubator.css"
import IncubatorForm from './IncubatorForm';

import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useCollection } from 'react-firebase-hooks/firestore';
import {  updateDoc, increment, deleteDoc, doc, collection, onSnapshot } from "firebase/firestore";
import { database } from '../../../firebase';
import { useAuth } from '../../../hooks/AuthContext';
import DeleteFileDialog from '../../../component/DeleteFileDialog';
import IncubatorEditForm from './IncubatorEditForm';





export default function Incubator() {


  const { currentUser } = useAuth();
  const [documentID, setDocumentID] = useState('');

  const [savedName, setSavedName] = useState('');
  const [incubatorName, setIncubatorName] = useState('');
  const [website, setWebsite] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);


   let myRef = collection(database, 'startupInformation', currentUser.uid, "incubator")


  const [value, loading, error] = useCollection(
    myRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [closeDialog, setCloseDialog] = useState(false);
  const [incubatorOpenDialog, setIncubatorOpenDialog] = useState(false);


  const handleDialogClose = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }
    setCloseDialog((closeDialog) => !closeDialog);


  };

  async function deleteDocument(docID) {
    const docRef = doc(database, "startupInformation", currentUser.uid, "incubator", docID);
    await deleteDoc(docRef);
  }

  const handleListItemClick = (incubatorName, website,docID) => {
    console.log("incubatorName " + incubatorName);
    setIncubatorName(incubatorName)
    setWebsite(website)
    setDocumentID(docID)
    setIncubatorOpenDialog(true);
  };


  const deleteItemhandle = (docID) => {
    setDocumentID(docID)
    setDialogOpen(true);
  };

  const handleDialogOpen = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }

    setIncubatorOpenDialog(false);

  };


  return (
    <>
      <PageTopComponent title={"Incubators and/or Accelerators "} buttonText={"Add"} onClick={handleDialogClose} />
      <IncubatorForm open={closeDialog} onClose={handleDialogClose} />
      <IncubatorEditForm open={incubatorOpenDialog} onClose={handleDialogOpen} incubatorName={incubatorName} website={website} docID={documentID}  />

      <DeleteFileDialog
        deleteText={"Are you sure you want to delete this data? This action cannot be undone."}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onDelete={() => deleteDocument(documentID)}
      />        
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Checking data...</span>}
        {value && (
          <span>
            {value.docs.map((docData) => (
              <React.Fragment key={docData.id}>
                <div style={{ paddingLeft: "15px", paddingRight: "25px", marginTop: "20px", height: "80px", border: "1px solid #1C1C1C", borderRadius: "10px", display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    


                    <div> <p className='cap-title-text'>{docData.data().incubatorName}</p>
                      <p className='cap-data-date'>{docData.data().website}</p></div>
                  </div>


                  <div><IconButton onClick={() => {
                    handleListItemClick( docData.id,docData.data().incubatorName, docData.data().website,docData.id);
                  }}> <EditIcon sx={{ color: "black", border: "1px solid black", borderRadius: "5px" }} /></IconButton>
                    <IconButton onClick={() => deleteItemhandle(docData.id)} ><Delete sx={{ color: "#FB9B9B", border: "1px solid #FB9B9B", borderRadius: "5px" }} /></IconButton></div>

                </div>

              </React.Fragment>

            ))}
          </span>
        )}


    </>
  )
}
