import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./sidenav.css";
import Chip from "@mui/material/Chip";
import { useMediaQuery } from "react-responsive";
import { Button } from "@mui/material";
import NorthEastIcon from '@mui/icons-material/NorthEast';

const useStyles = { backgroundColor: '#E2FBD7', color: '#1C1C1C', }

const SideNav = ({ onClose, userName }) => {

  const [activeLink, setActiveLink] = React.useState(() => {
    // Attempt to retrieve state from localStorage
    const savedState = localStorage.getItem('Onboarding');
    return savedState ? JSON.parse(savedState) : "Onboarding";
  });

  React.useEffect(() => {
    
    localStorage.setItem('Onboarding', JSON.stringify(activeLink));

  }, [activeLink]);




  const handleLinkClick = (link) => {
    onClose();

     setActiveLink(link);

  };



  return (

    <div className="side-nav-wrapper">


      <div className="side-menu">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <NavLink style={{
            fontFamily: "dmsans",
            textDecoration: "none",
            color: " #1C1C1C"

          }} onClick={onClose}  to="/profile">
            {userName}
          </NavLink>
          <Button onClick={onClose} sx={{fontFamily: "dmsans",
            textDecoration: "none",
            textTransform:"none",
            color: " #1C1C1C"}}>Close</Button>
        </div>

        <div style={{marginTop:"2rem"}}>
          <h5 style={{ marginBottom: ".5rem" }}>Fundraising</h5>
          <Link style={{ textDecoration: 'none' }} to={`onboarding`}

            onClick={() => handleLinkClick("Onboarding")}

          >
            <div className={`side-link u-link ${activeLink === "Onboarding" ? "active" : ""}`}>

              Onboarding
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} to={`startupinformation`} replace onClick={() => handleLinkClick("startupinformation")} >

            <div className={`side-link u-link ${activeLink === "startupinformation" ? "active" : ""}`}>
              Startup Information
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} to={`investmentround`} replace onClick={() => handleLinkClick("investmentround")} >
            <div className={`side-link u-link ${activeLink === "investmentround" ? "active" : ""}`}>

              Investment Round
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} to={`pitchstory`} replace onClick={() => handleLinkClick("pitchstory")}  >

            <div className={`side-link u-link ${activeLink === "pitchstory" ? "active" : ""}`}>
              Pitch Story
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} to={`startupopportunity`} replace onClick={() => handleLinkClick("startupopportunity")}>

            <div className={`side-link u-link ${activeLink === "startupopportunity" ? "active" : ""}`}>
              Startup Opportunity
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} replace to={`emailblurp`} onClick={() => handleLinkClick("emailblurp")}>

            <div className={`side-link u-link ${activeLink === "emailblurp" ? "active" : ""}`}>
              Email Blurp
            </div>
          </Link>



          <Link style={{ textDecoration: 'none' }} to={`findinvestor`} replace onClick={() => handleLinkClick("findinvestor")}>

            <div className={`side-link u-link ${activeLink === "findinvestor" ? "active" : ""}`}>

              Find Investors


            </div>
          </Link>
        </div>

        <h5 style={{ marginBottom: ".5rem" }}>Metrics</h5>

        <Link style={{ textDecoration: 'none' }} to={`kpi`} replace onClick={() => handleLinkClick("kpi")}>
          <div className={`side-link u-link ${activeLink === "kpi" ? "active" : ""}`}>
            KPIs
          </div>
        </Link>
        <div>
          <br />
          <h5 style={{ marginBottom: ".5rem" }}>Operations</h5>
          <Link style={{ textDecoration: 'none' }} to={`dataroom`} replace onClick={() => handleLinkClick("dataroom")}  >

            <div className={`side-link u-link ${activeLink === "dataroom" ? "active" : ""}`}>
              Data Room
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} to={`captable`} replace onClick={() => handleLinkClick("cabtable")}>

            <div className={`side-link u-link ${activeLink === "captable" ? "active" : ""}`}>
              Captable
            </div>
          </Link>

          <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >
            {" "}
            <div className={` side-link disable-links u-link  ${activeLink === "docsign" ? "active" : ""}`} >
              Document Signing{" "} <span>
                {" "}
                <Chip label="coming soon" color="success" size="small" sx={useStyles} />

              </span>
            </div>
          </Link>



          <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >
            {" "}
            <div className={`side-link u-link ${activeLink === "dealroom" ? "active" : ""}`}>

              Deal Room{" "} <span>
                {" "}
                <Chip label="coming soon" color="success" size="small" sx={useStyles} />

              </span>

            </div>
          </Link>
        </div>

        <div>
          <h5 style={{ marginBottom: ".5rem" }}>Investors Report</h5>
          <Link style={{ textDecoration: 'none' }} replace className='disable-links'   >
            {" "}
            <div className={`side-link u-link ${activeLink === "investors" ? "active" : ""}`}>
              Investors {" "} <span>
                {" "}
                <Chip label="coming soon" color="success" size="small" sx={useStyles} />

              </span>
            </div>
          </Link>




          <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >

            <div className={`side-link u-link ${activeLink === "updates" ? "active" : ""}`}>

              Updates{" "}
              <span>
                {" "}
                <Chip label="coming soon" color="success" size="small" sx={useStyles} />

              </span>

            </div>
          </Link>
        </div>
        <div className="side-menu-bottom">
          <h5 style={{ marginBottom: ".5rem" }}>Help</h5>

          <Link style={{ textDecoration: 'none' }} replace to={`talkwithexpert`}

            onClick={() => handleLinkClick("talkwithexpert")}

          >
            <div className={`side-link u-link ${activeLink === "talkwithexpert" ? "active" : ""}`}>
              Talk With Expert
            </div>
          </Link>


<div style={{ marginTop:"2rem",display: 'flex', flexDirection: "row", alignItems: "center", justifyItems: "center", justifyContent: "center" }}>
  <NavLink activeClassName="active" to="/membership" >
    <Button sx={{ color: "black", textTransform: "none", borderColor: "black" }} variant='outlined' onClick={onClose} >   Membership
      <NorthEastIcon fontSize='small' sx={{
        width: "0.9rem",
        height: " 0.9rem"
      }} />
      </Button>

  </NavLink></div>
  <a style={{marginTop:"1rem", fontFamily: "dmsans",
            textDecoration: "none",
            color: " #1C1C1C",display:"flex",flexDirection:"row",justifyContent:"center"}} rel="noreferrer" href="https://join.slack.com/t/dozerovc/shared_invite/zt-249zvkklx-tMFhYJMdTuuLnj1g0AzbQA" target="_blank">    Slack Community</a>



          <div className="version">
            V1.0.1.3 beta
          </div>
        </div>
      </div>

    </div>

  );
};

export default SideNav;
