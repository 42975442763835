import React from 'react'
import ListComponent from '../../component/List/ListComponent'
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';

const Email = () => {
  const dataList = [
    'Ask for a VC Intro',
    'Follow-up after VC Intro',
    'Cold email to Investors',
    'Cold email to Founders',
];
const pathList = [
    'Ask for VC Intro',
    'Follow-up after intro',
    'Cold email to investors',
    'Cold email to founders'

];

  return (
    <div className='mobile-friendly'>
      <PageHeadingLabel headingText={"Email Blurp"}/>
      <br/>
      <ListComponent pathList={pathList} dataList={dataList} />
      </div>
  
  )
}

export default Email