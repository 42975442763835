import React from 'react'
import './startUpInformation.css'
import ListComponent from '../../component/List/ListComponent';
import PageHeadingLabel from "../../component/PageHeadingLabel/PageHeadingLabel"
const StartUpInformation = () => {

  const dataList = [
    'Startup Identity',
    'Founders',
    'Incubators and/or Accelerators'
  ];
    const pathList = [
    'startup_identity',
    'founder',
    'incubator'
  ];
  
  return (
   
     
       <div className='mobile-friendly'> <PageHeadingLabel headingText={"Startup Information"} /> 
      <br/>
      <ListComponent pathList={pathList} dataList={dataList} />
      
      </div>
     
    
  );
}


export default StartUpInformation