import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, payments } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { onCurrentUserSubscriptionUpdate } from '@invertase/firestore-stripe-payments';
import OnBoarding from '../OnBoarding/OnBoarding';

const Home = () => {
  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
       if (!user.emailVerified) {
          navigate('/emailverified', { replace: true });
        } else {
          const subscriptionUpdateListener = onCurrentUserSubscriptionUpdate(
            payments,
            (snapshot) => {

              if(snapshot.empty){
                navigate('/memberships', { replace: true });
              }else{
                for (const change of snapshot.changes) {
                  console.log("change.subscription.status " + change.subscription.status ==="trialing");
                  if (change.subscription.status === 'active' || change.subscription.status === 'trialing' ) {
                    navigate('/', { replace: true });
                  } else {
                    navigate('/memberships', { replace: true });
                  }
                }
              }

             
        
            }
          );

          return () => {
            subscriptionUpdateListener();
          };
        }
      } else {
        navigate('/login', { replace: true });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);


    return <OnBoarding  />;



};






export default Home;
