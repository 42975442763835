import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { Button, SvgIcon } from '@mui/material';
import LabelBackButton from '../../../component/LabelBackButton';
import "./RoundStage.css"
import {useLocation, useNavigate } from 'react-router-dom';

const RoundStage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const radioOptions = ["Angel Round", "Pre Seed", "Seed", "Series A", "Other"];
  const [roundStage, setRoundStage] = useState("");


  useEffect(()=>{
    if(location.state != null){
      setRoundStage(location.state.roundStage);
    }
  },[])

  const handleRadioChange = (event) => {
    console.log("targetSize " +event.target.value);
    setRoundStage(event.target.value);
  };
  const handleListItemClick = (value) => {
    console.log("targetSize " +value);

    setRoundStage(value);
  };

  const styles = {
    color: '#1C1C1C',
    fontFamily: 'dmsans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    marginLeft:"3.9rem",
    marginTop:"0.5rem",
    lineHeight: 'normal',
    
  };


  


  return (
    <>

    <LabelBackButton title={"What round is this?"} />
    <p style={styles}> Choose a stage and we'll help you create your round quickly.</p>
    <List dense sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
    {radioOptions.map((value) => {
      const labelId = `checkbox-list-secondary-label-${value}`;
      const isSelected = roundStage === value;
      const borderStyle = isSelected ? '2px solid black' : '0.2px solid lightgrey';

      return (
        <ListItem
          onClick={()=>handleListItemClick(value)}
          sx={{ border: borderStyle, borderRadius: "10px", marginTop: '1rem' }}
          key={value}
        >
          <ListItemButton>
            <ListItemText id={labelId} primary={`${value}`} />
          </ListItemButton>

          <FormControl>
            
            <Radio
            sx={{ 
            '&.Mui-checked': {
              color: 'black', // checked color
            },}}
              checked={isSelected}
              onChange={handleRadioChange}
              value={value}
            />
          </FormControl>
        </ListItem>
      );
    })}
  </List>
  <br/>

    
  <Button 
  disabled={roundStage?.length===0} onClick={()=>navigate("instrument",{ state: { roundStage: roundStage } })}
  variant='outlined'  sx={{ color: "black", borderColor: "black", textTransform: "none" ,width:"20%"}} >Continue</Button>

  

  </>
    
  );
};

export default RoundStage;
