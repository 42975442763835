import { Button } from '@mui/material'
import React from 'react'
import LabelBackButton from '../LabelBackButton';


export default function PageTopComponent(props) {
  return (
    <div className='data-upload'>
    <LabelBackButton title={props.title} />
    <Button variant='outlined' disabled={props.disabled}  onClick={props.onClick} sx={{paddingRight: "1rem",
 textTransform: "none", width: props.buttonWidth, borderRadius: "5px", borderColor: "black", color: "black", fontFamily: 'dmsans', fontSize: '0.9rem' }}>{props.buttonText}</Button>
  </div>
  )
}
