import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import { useForm, Controller } from 'react-hook-form';
import { Box, DialogTitle, FormGroup, FormLabel, IconButton, MenuItem, Stack } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import isEmail from 'validator/lib/isEmail';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from "date-fns";
import { Timestamp, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../hooks/AuthContext';
import { database } from '../../firebase';



const isEmailValid = (value) => {
    return isEmail(value) || "Invalid email address";
};

const menuitems = {
    'All stages': '#1C1C1C',
    'Meeting': '#AB486D',
    'Due Diligence': '#0B8800',
    'Partner Meeting': '#55918B',
    'Term Sheet': '#00A7B1',
    'Closed': '#0066FF',
    'Keep in touch': '#FF8585',
    'Passed': '#FF0000',
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme => theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
        padding: theme => theme.spacing(1),
    },
}));



const AddMeetingDialog = ({ open, onClose, meeting }) => {
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors }, setValue
    } = useForm();


    const { currentUser } = useAuth();



    function convertStringToTimestamp(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        const timestamp = Timestamp.fromDate(new Date(year, month - 1, day));
        return timestamp;
    }

    const onSubmit = async(data) => {
        console.log(data, errors);
        if (Object.keys(errors).length === 0) {
            console.log("meeting " + meeting.initialContact);

  await updateDoc(doc(database, 'findInvestors', currentUser.uid, "investors",meeting.docID), {
    amount:data.amount,
    initialContact:convertStringToTimestamp(data.initialContact),
    nextFollowUp:convertStringToTimestamp(data.nextFollowUpDate),
    primaryContact:data.primaryContact,
    rating:data.rating,
    stage:data.stage,
    updatedAt:Timestamp.fromDate(new Date())
     
        });

            onClose();
        } else {
            // Handle the display of errors

            console.log("errror " + errors);
        }
    };


    useEffect(() => {
        console.log("dociD "  + meeting.docID);
        setValue("name", meeting.name)
        setValue("amount", meeting.amount)
        setValue("rating", meeting.rating)
        setValue("stage", meeting.stage)
        setValue("primaryContact", meeting.primaryContact)

        if (meeting.initialContact) {

            const initialDate = (meeting.initialContact).toDate();
            setValue("initialContact", format(initialDate, "yyyy-MM-dd"))

        }
        if (meeting.nextFollowUp) {
            const followupDate = (meeting.nextFollowUp).toDate();
            setValue("nextFollowUpDate", format(followupDate, "yyyy-MM-dd"))

        }



    }, [meeting, setValue])

    return (

        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            disableBackdropClick
            fullWidth
            borderRadius="10px"
            maxWidth="xs"
        >

            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Edit Investment </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} sx={{ width: { xs: '100%', sm: '400px', md: '30rem' } }}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled
                                        label="Name"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                )}

                            />


                            <Controller
                                name="stage"

                                control={control}
                                defaultValue="Meeting"
                                render={({ field }) => <TextField fullWidth  {...field}

                                    select





                                    type="text"  >
                                    {Object.keys(menuitems).map((customerType) => (
                                        <MenuItem

                                            key={customerType}
                                            value={customerType}
                                            style={{
                                                padding: 0,
                                                color: menuitems[customerType],
                                            }}
                                        >
                                            {customerType}
                                        </MenuItem>
                                    ))}
                                </TextField>} />




                            <Controller
                                name="amount"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Amount is required",
                                    pattern: {
                                        value: /^\d+$/,
                                        message: 'Invalid amount. Please enter digits only.',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Amount"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        error={Boolean(errors.amount)}
                                        helperText={errors.amount && errors.amount.message}
                                    />
                                )}
                            />
                            <Controller
                                name="primaryContact"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Email is required",
                                    validate: isEmailValid,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        error={Boolean(errors.primaryContact)}
                                        helperText={errors.primaryContact && errors.primaryContact.message}
                                    />
                                )}
                            />

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid lightgrey", padding: "15px", borderRadius: "10px" }}>   <span>Rating</span>
                                <Controller
                                    name="rating"
                                    control={control}
                                    defaultValue={0}
                                    render={({ field }) => (

                                        <Rating {...field} name="rating" precision={0.5} />
                                    )}
                                /></div>


                            <Controller
                                name="initialContact"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Initial Date"
                                        type="date"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="nextFollowUpDate"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Next Follow-up Date"
                                        type="date"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Button type="submit" variant="outlined" sx={{ textTransform: "none", borderColor: "black", color: "black" }} style={{ marginTop: "2rem" }}  >
                            Update
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </BootstrapDialog>
    );
};

export default AddMeetingDialog;
