import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Button, FormControl, FormGroup, FormLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import logo from "../../assets/dozerologo.png";
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { doc, setDoc } from 'firebase/firestore';
import { database } from '../../firebase';
import './register.css';
import {sendEmailVerification,onIdTokenChanged } from "firebase/auth";


const Register = () => {

  const { handleSubmit, control, formState: { errors } } = useForm();
  ;
  const navigate = useNavigate();

  const [isPasswordVisible, setPasswordVisibility] = useState(true);

  function handleClickShowPassword() {
    setPasswordVisibility((isPasswordVisible) => !isPasswordVisible);
  }


  const isEmailValid = (value) => {
    return isEmail(value) || "Invalid email address";
  };


  function showErrorDialog(errorMessage) {
    return Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: errorMessage,
      showConfirmButton: true,

    })

  }
  const onRegister = async (data) => {

    try {
      // Create a new user with email and password
      await createUserWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);

          const userDocRef = doc(database, "users", user.uid);
          const docRef =doc(database, 'findInvestors', user.uid);

       await setDoc(docRef,{"status":"pending"})
        
        
            try{
                await sendEmailVerification(user).then(() => {
                    console.log("emailverification sent");
        

            //         const unsubscribeOnUserChanged = 
            // onIdTokenChanged(auth, (response) => {
            //    const unsubscribeSetInterval = setInterval(() => {
            //     console.log("internval is running")
            //     //this works as a next in for-like
            //       auth.currentUser.reload();
            //    }, 500);
            //   if (response.emailVerified) {

            //      clearInterval(unsubscribeSetInterval); //stop setInterval
            //      console.log("internval is stopped")

            //      navigate("/",{ replace: true })

            //     return unsubscribeOnUserChanged(); //unsubscribe onUserChanged
            //   } 
            // });
                    // Email verification sent!
                    // ...
                  });
            }catch(e){
                console.log("emailverification error " + e);
            }
            

          await setDoc(userDocRef, {
            email:
             user.email,
            likedinProfileUrl:
             data.linkedIn,
            name:
              data.name,
            positionInCompany:
              data.role,
            profileImageUrl: "",
            uid:user.uid,
            isVerified:user.emailVerified
          });

          navigate("/")
          // ...
        })
        .catch((error) => {


          if (error.code === "auth/email-already-in-use") {
            showErrorDialog("The email address is already in use");



          } else if (error.code === "auth/invalid-email") {
            showErrorDialog("The email address is not valid");



          } else if (error.code === "auth/operation-not-allowed") {
            showErrorDialog("Operation not allowed.");

          } else if (error.code === "auth/too-many-requests") {
            showErrorDialog("Your account is temporary blocked ,Please try again later");


          }

        })
    }
    catch (error) {
      console.error('Error creating user:', error);
    }
  };


  const myStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/pic/background.png"})`,
    height: '95vh',
    width: '50vw',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };


  return (
    <>
      <div className="logo"><img src={logo} style={{}} alt='Dozero logo'></img></div>


      <div className="register-container">
        <div className="image-container">
          <div style={myStyle} alt="login" />

          <div className="login-text-overlay">“Dozero offers expert guidance and a wide range of resources to help me and my startup navigate the investment process with confidence.”</div>
          <div className="login-text-overlay-founder">Ava Davis - Co-Founder</div>
        </div>


        <div className="register-form">
          <h1 className='reg-title'>Register for Free</h1>

          <FormControl fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>

              <FormGroup >
                <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}>First and Last Name</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Name is required'
                  }}
                  render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="First and Last Name" />}
                />



                {errors.name && <p className="error-text">{errors.name.message}</p>}
              </FormGroup>
              <br />
              <FormGroup >
                <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}>Email</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Email is required', validate: isEmailValid
                  }}
                  render={({ field }) => <TextField {...field} fullWidth type="email" placeholder="elon@musk.com" />}
                />
                {errors.email && <p className="error-text">{errors.email.message}</p>}
              </FormGroup>
              <br />
              <FormGroup >
                <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}>Password</FormLabel>


                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{

                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                  }}
                  render={({ field }) => <TextField {...field} fullWidth type={isPasswordVisible ? "password" : "text"} placeholder="your password"
                    InputProps={{
                      endAdornment:

                        <InputAdornment position="end">

                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >{isPasswordVisible ?
                            <Visibility /> : <VisibilityOff />}


                          </IconButton>
                        </InputAdornment>
                    }} />}
                />
                {errors.password && <p className="error-text">{errors.password.message}</p>}
              </FormGroup>
              <br />
              <FormGroup >
                <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}> LinkedIn Profile</FormLabel>
                <Controller
                  name="linkedIn"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="https://www.linkedin.com/in/elonmusk" />}
                />

              </FormGroup>
              <br />
              <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}> Position in the Company</FormLabel>
                <Controller
                  name="role"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="Role in the company" />}
                />

              </FormGroup>

              <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <Button variant='outlined' sx={{ color: "black", borderColor: "black", textTransform: "none" }} type="submit" className="btn-register">Create account</Button>
</div>
            </form>

          </FormControl>



<div style={{ textAlign: 'center', marginTop: '20px' }}>
  <span className='tos-privacy-text'>By continuing you confirm that you accept our <Link to="https://www.dozero.vc/terms-and-conditions" target='_blank' style={{ color: "black" }}>Terms and Conditions</Link> and  <Link to="https://www.dozero.vc/privacy-policy" target='_blank' style={{ color: "black" }}>Privacy Policy</Link></span>
</div>

<div style={{ textAlign: 'center', marginTop: '10px' }}>
  <span className='reg-login-text'>Already registered? <Link style={{ color: "black" }} to="/login">Login</Link></span>
</div>

          {/* <span className='tos-privacy-text'>By continuing you confirm that you accept our <Link to="https://www.dozero.vc/terms-and-conditions" target='_blank' style={{color:"black"}}><br />Terms and Conditions</Link> and  <Link to="https://www.dozero.vc/privacy-policy" target='_blank' style={{color:"black"}}>Privacy Policy</Link></span>
          <span className='reg-login-text'>already registered?<Link style={{color:"black"}} to="/login"> Login </Link> </span>
        */}
       
       
        </div>

      </div>


    </>
  );
};

export default Register;
