import { ArrowBackIosNew, CloudCircleOutlined, Hearing } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import ArrowBackIosIcon from '@mui/icons-material/ArrowForwardIos';

import * as loom from '@loomhq/loom-embed';


const SideNavRight = ({ visbile, onClose, selectedLeftNavItem, }) => {

  return (
    <>


      {visbile ? <Box sx={{
        display: "block", width: "23.625rem",

      }} style={{ background: "rgba(28, 28, 28, 0.02)" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <CloudCircleOutlined />
            <p style={{
              color: "#1C1C1C",
              fontFamily: "dmsans",
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}>Artifacts</p>
          </div>

          <IconButton onClick={onClose} sx={{
            border: "1px solid black", borderRadius: "10%", width: "3.5rem",
            height: "1.6875rem"
          }} >
            <ArrowBackIosIcon fontSize='medium' style={{

              display: "flex",
              marginLeft: "0.2rem",
              fontSize: "1rem",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexShrink: 0
            }} />
          </IconButton>
        </div>
        <div style={{ padding: "1.5rem" }}>
          <p className='art-info-text'>Need help preparing for investment? Our platform provides comprehensive guidance and support to founders and startups
          </p>

          <h5>About {selectedLeftNavItem}</h5>

          {selectedLeftNavItem == "Captable" ?
            <div style={{ position: "relative", paddingBottom: "56.14583333333334%", "height": 0 }}>
              <iframe src="https://www.loom.com/embed/37abc9877afa45c8b761be111dafa03b?sid=69d690f3-f3c9-4b9c-90b0-e857592f2bfe" frameborder="0" webkitallowfullscreen mozallowfullscreen allow allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "300px" }}></iframe></div> : null}
            
     { selectedLeftNavItem == "Captable" ?      <div style={{marginTop:"8rem",marginBottom:"5rem"}}>           
             <h4>Summary</h4>
            <Typography>
In this video, I explain the concept of dilution and the importance of shareholders agreements in equity rounds. I discuss how dilution is calculated and its impact on investors and shareholders.<br/> I also cover the five key elements of a shareholders agreement, including dilution, board seats, tag along and drag along clauses, and the option pool. Watch this video to gain a better understanding of these crucial aspects of equity rounds.</Typography></div> : null}

          {selectedLeftNavItem == "Investment Round" ?
            <div style={{ position: "relative", paddingBottom: "56.14583333333334%", "height": 0 }}>
              <iframe src="https://www.loom.com/embed/fbc8f35f2e0449dabcd716cd7d6f9b0b?sid=ffd73fbb-1bc3-491f-8b66-0b84ed9921ac" frameborder="0" webkitallowfullscreen mozallowfullscreen allow allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "300px" }}></iframe></div> : null}
          
              { selectedLeftNavItem == "Investment Round" ?      <div style={{marginTop:"8rem",marginBottom:"5rem"}}>           
             <h4>Summary</h4>
            <Typography>
            In this video, I explain the difference between equity-round and convertible notes when raising funds for your company. I discuss the four elements needed to calculate the percentage of your company that you are exchanging for money, as well as the factors to consider when determining the pre-money valuation. I also provide examples and insights on how to calculate the investment needed and the post-money valuation. This video will help you understand the key concepts and considerations when deciding between equity-round and convertible notes for fundraising.</Typography></div> : null}

          
           {/* {selectedLeftNavItem == "Investment Round" ?
            <div style={{marginTop:"2rem", position: "relative", paddingBottom: "56.14583333333334%", "height": 0 }}>
              <iframe src="https://www.loom.com/embed/debb6d974d404f2da6c8dc79b972b1a9?sid=b38499de-45dc-49f7-92cf-6b677075d15f" frameborder="0" webkitallowfullscreen mozallowfullscreen allow allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "300px" }}></iframe></div> : null}      */}


          {selectedLeftNavItem == "Startup Information" ?
            <div style={{ position: "relative", paddingBottom: "56.14583333333334%", "height": 0 }}>
              <iframe src="https://www.loom.com/embed/610fa7e184c24a73acef1034319bf643?sid=a03f7e92-3087-49c6-a5f8-d27b3c8c0eb9" frameborder="0" webkitallowfullscreen mozallowfullscreen allow allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "300px" }}></iframe></div> : null}

              { selectedLeftNavItem == "Startup Information" ?      <div style={{marginTop:"8rem",marginBottom:"5rem"}}>           
             <h4>Summary</h4>
            <Typography>
            In this video, I discuss the importance of having a clear and concise one-liner to explain what your company does.<br/>I emphasize the need to avoid vague statements and instead focus on communicating the core essence of your business. By using the formula "My company is developing a defined [insert your industry/sector]", you can effectively convey your company's purpose. Watch this video to learn how to create a compelling one-liner that accurately represents your business.</Typography></div> : null}

          {selectedLeftNavItem == "Onboarding" ?
            <div style={{ position: "relative", paddingBottom: "56.14583333333334%", "height": 0 }}>
              <iframe src="https://www.loom.com/embed/fbdeca1aca574b34946d1ad017861fc1?sid=af809cb3-1fab-4a07-acd0-42b7cbae79bc" frameborder="0" webkitallowfullscreen mozallowfullscreen allow allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "300px" }}></iframe></div> : null}

              { selectedLeftNavItem == "Onboarding" ?      <div style={{marginTop:"8rem",marginBottom:"5rem"}}>           
             <h4>Summary</h4>
            <Typography>
            Hi, I'm Raul, one of the co-founders of the do zero platform. In this video, I'll introduce you to our platform, which is designed to help you learn how to raise money in exchange for equity. I'll be your guide throughout the process, providing step-by-step instructions and valuable insights. Get ready to embark on your fundraising journey with do zero!</Typography></div> : null}
        </div>



      </Box> : null}

      

    </>

  )
}

export default SideNavRight