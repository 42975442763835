import React from 'react'
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import "../TalkWithExpert/talkwithexpert.css";
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';


const TalkWithExpert = () => {
  return (
    <>
    <div className='talkwithexpert'>
      <PageHeadingLabel headingText={"Talk with an expert and increase the chances of success "}/>
    
    <br/>
    <p className='expert-text'>Work with our team of VCs/industry experts to build your fundraising strategy and perfect your pitch and data room.</p>
   <br/>
    <p className='expert-text'>At Dozero, we want all your documentation and your investment strategy to be correct. For this reason, we put at your disposal a team of experts that will help you with the following areas:</p>
    <br/>
    <ul className='expert-text' style={{paddingLeft:'1rem'}}>
        <li>Review and analysis of the Investment Round and Startup Opportunity - Recommended time of 30 min.</li>
        <li>Review and analysis of Financial Model - Recommended time of 60 min</li>
        <li>Review and analysis of the Valuation of the company and Amount of the Round - Recommended time of 30 min.</li>
        <li>Review and analysis of the Pitch Story - Recommended time of 30 min.</li>
        <li>Review and analysis of the Data Room in general - Recommended time of 60 min.</li>
    </ul>
    <br/>
    <div className='expert-text'>This service has an additional cost for free and premium plan.</div>
    <br/>
    <div className='expert-text' >Note: We need to review the documentation you have provided prior to the meeting. If you have not yet uploaded the documents to the platform, please do so as soon as possible. If you have any questions, please feel free to contact us.</div>
    
    <div className='hire-boxes'>
    <Box
      sx={{
        width: 260,
        height: 306,
        display:'flex',
        flexDirection:'column',
        justifyContent:"space-around",
        justifyItems:'center',
        alignItems:"center",
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '0.5rem',
background:"var(--white-100, #FFF)"
      }}
    >
     
      <div style={{display:'flex',flexDirection:"column",alignItems:"center"}}> 
        <div style={{fontSize:'3rem',fontFamily:"boldsans"}}>30</div>
      <div style={{fontSize:'1.5rem'}}>min</div>
      </div>
      
      <p style={{fontFamily:"dmsans" ,fontSize:"1rem"}} >session price €79</p>
      
      <Button variant='outlined' sx={{width:"150px",color:"black",textTransform:"none",borderColor:"black"}} href='https://buy.stripe.com/eVa0162U53aO44EcMN'
  target='_blank' >Hire</Button>
      
    </Box>
    
    <Box
      sx={{
        width: 260,
        height: 306,
        display:'flex',
        flexDirection:'column',
        justifyContent:"space-around",
        justifyItems:'center',
        alignItems:"center",
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '0.5rem',
background:"var(--white-100, #FFF)"
      }}
    >
     
      <div style={{display:'flex',flexDirection:"column",alignItems:"center"}}> <div style={{fontSize:'3rem',fontFamily:"boldsans"}}>60</div>
      <div style={{fontSize:'1.5rem'}}>min</div></div>
      
      <p style={{fontFamily:"dmsans" ,fontSize:"1rem"}} >session price €99</p>
      
      <Button variant='outlined' sx={{width:"150px",color:"black",textTransform:"none",borderColor:"black"}} href='https://buy.stripe.com/7sIbJO52d26KcBa146'
  target='_blank' >Hire</Button>
      
    </Box>
    </div>
    </div>
    
    </>

  )
}

export default TalkWithExpert