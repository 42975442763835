import React, {  } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login'
import NotFound from './pages/NotFound/NotFound';
import Register from './pages/Register/Register';
import OnBoarding from './pages/OnBoarding/OnBoarding';
import Startup from './pages/Startup/Startup';
import StartUpInformation from './pages/StartUpInformation/StartUpInformation';
import Investment from './pages/Investment/Investment';
import Pitch from './pages/Pitch/Pitch';
import Email from './pages/Email/Email';
import Home from './pages/Home/Home';
import Membership from './pages/Membership/Membership';
import TalkWithExpert from './pages/TalkWithExpert/TalkWithExpert';
import Profile from './pages/Profile/Profile';
import DataRoom from './pages/DataRoom/DataRoom';
import Captable from './pages/Captable/Captable';
// import Layout from './pages/Layout/Layout';
import KPI from './pages/KPI/Kpi';

import PrivateRoute from "./component/PrivateRoute/PrivateRoute"
import StartupIdentity from './pages/StartUpInformation/StartupIdentity/StartupIdentity';
import MainDataRoom from './component/MainDataRoom/MainDataRoom';
import FindInvestor from './pages/FindInvestor/FindInvestor';

import Founder from './pages/StartUpInformation/Founder/Founder';
import Incubator from './pages/StartUpInformation/Incubator/Incubator';
import RoundStage from './pages/Investment/RoundStage/RoundStage';
import Root from './AppDrawer';
import EmailBlurp from './component/EmailBlurpContent/EmailBlurp';
import PreMoneyVal from './pages/Investment/PreMoneyVal/PreMoneyVal';
import Layout from './pages/Layout/Layout_old';
import EmailVerified from './pages/EmailVerified/EmailVerified';
import InvestmentPipeline from './pages/FindInvestor/InvestmentPipeline';
import InstrutmentType from './pages/Investment/InstrutmentType/InstrutmentType';
import TargetSize from './pages/Investment/TargetSize/TargetSize';
import Equity from './pages/Investment/Equity/Equity';
import Term from './pages/Investment/Term/Term';
import Memberships from './pages/Membership/Memberships';
import EmailActionHandler from './component/EmailActionHandler/EmailActionHanlder';



function App() {

  return (


    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
        <Route path="emailverified" element={<PrivateRoute Component={EmailVerified} />} />
        <Route path="memberships" element={<PrivateRoute Component={Memberships} />} />
        <Route path="action/:mode/:oobCode" element={<EmailActionHandler />} />

        <Route path="/" element={<PrivateRoute Component={Root} />}>

          <Route index element={<Home />} />

          <Route path="captable" element={<Captable />} />
          <Route path="startupinformation" element={<StartUpInformation />} > </Route>
          <Route path="startupinformation/startup_identity" element={<StartupIdentity />} />
          <Route path="startupinformation/founder" element={<Founder />} />
          <Route path="startupinformation/incubator" element={<Incubator />} />

          <Route path="onboarding" element={<OnBoarding />} />

          <Route path="investmentround" element={<Investment />} />
          <Route path="investmentround/roundtype/premoney" element={<PreMoneyVal />} />

          <Route path="investmentround/roundstage" element={<RoundStage />} />
          <Route path="investmentround/roundstage/instrument" element={<InstrutmentType />} />
          <Route path="investmentround/roundstage/instrument/ts" element={<TargetSize />} />
          <Route path="investmentround/roundstage/instrument/ts/eq" element={<Equity />} />
          <Route path="investmentround/roundstage/instrument/ts/eq/tm" element={<Term />} />




          <Route path="membership" element={<Membership />} />
          <Route path="profile" element={<Profile />} />
          <Route path="talkwithexpert" element={<TalkWithExpert />} />
          <Route path="kpi" element={<KPI />} />
          <Route path="findinvestor" element={<InvestmentPipeline />} />
          <Route path="investmentpipeline" element={<InvestmentPipeline/>} />

          <Route path="emailblurp" element={<Email />} />
          <Route path="emailverified" element={<EmailVerified />} />

          <Route path="emailblurp/:name" element={<EmailBlurp />} />

          <Route path="startupopportunity" element={<Startup />} >

          </Route>
          <Route path="pitchstory" element={<Pitch />} />
       

          {/* <Route path="root" element={<Root />} /> */}

          <Route path="dataroom" element={<DataRoom />} />

          <Route path="dataroom/:name" element={<MainDataRoom />} />

          <Route path="startupopportunity/:name" element={<MainDataRoom />} />

        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
