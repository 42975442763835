import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth} from '../../firebase';



const EmailActionHandler = () => {
  const navigate = useNavigate();
  const { mode, oobCode } = useParams();

  useEffect(() => {
    const handleEmailAction = async () => {
      try {
        if (mode === 'resetPassword') {
          // Verify the password reset code and handle accordingly
          await auth.verifyPasswordResetCode(oobCode);
          // Redirect to the password reset page with the code
          navigate(`/reset-password/${oobCode}`);
        } else if (mode === 'recoverEmail') {
          // Handle recovering email action
          // Implement your logic here
        } else {
          // Handle other email action modes as needed
          // Implement your logic here
        }
      } catch (error) {
        console.error(error.message);
        // Redirect to an error page or handle the error as per your application's requirements
        navigate('/error');
      }
    };

    handleEmailAction();
  }, [mode, oobCode, navigate]);

  return (
    <div>
      <p>Handling email action...</p>
      {/* You can add a loader or other UI elements here */}
    </div>
  );
};

export default EmailActionHandler;
