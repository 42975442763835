
import React, { useState } from 'react'
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import { Button } from '@mui/material';
import UploadFileDialog from '../../component/UploadFileDialog/UploadFileDialog';
import { useMediaQuery } from 'react-responsive';
import FileList from '../../component/FileList/FileList';



const Pitch = () => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenUploadDialog((openUploadDialog) => !openUploadDialog)
  };
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })



  return (
    <>  
    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",justifyItems:"space-between"}}>
    <PageHeadingLabel headingText={"Pitch Story"}/>
    <Button variant='outlined' onClick={handleClickOpen} sx={{color:"black",borderColor:"black"  ,textTransform:"none" }} >{ isMobile ?"Upload":" Upload Pitch Story"}</Button>
    </div>
    <UploadFileDialog open={openUploadDialog} onClose={handleClickOpen} title={"Pitch Story"} fileName={"Pitch Title"} fileTitle={"Pitch File"}/>

    <FileList colName={"pitchStory"} isComingFromPitch={true} />
    </>
  
  ) 
}

export default Pitch