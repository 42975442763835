import { Menu } from '@mui/icons-material';
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import SideNav from './component/SideNav/SideNav';

const SideBarDemo = ({ open, onClose,userName }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
  
    const handleToggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
      if (onClose) {
        onClose(!sidebarOpen);
      }
    };
  
    return (
      <div>
      
        <Drawer anchor="top"
        open={open}
        onClose={handleToggleSidebar}
        style={{ width: '100vw', maxWidth: '100%' }}>
          <List>
            <SideNav open={open} onClose={onClose} userName={userName}/>        
          </List>
        </Drawer>
      </div>
    );
  };

export default SideBarDemo;
