import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Artifacts from  "../src/component/SideNavRght/SideNavRight"
import { Link, NavLink, Outlet } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import {  database } from './firebase';

import "./AppDrawer.css";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "./hooks/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import SideBarDemo from "./SideBarDemo";

const drawerWidth = 264;
const drawerWidthRight = 378;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open,isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: "16px",
    marginLeft:isMobile ? "0px": `-${drawerWidth -50}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);




const AppMainContent = styled("mainContent", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open,isMobile }) => ({
  backgroundColor: "#ffffff",
  color: "#1c1c1c",
  boxShadow: "none",
  display: "flex",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {

    width:isMobile ? "100%" :`calc(100% - ${drawerWidthRight}px)`,
    marginRight:isMobile ? "0px": `${drawerWidthRight}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open,isMobile }) => ({
  backgroundColor: "#ffffff",
  color: "#1c1c1c",
  boxShadow: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    
    width:isMobile ? "100%": `calc(100% - ${drawerWidth}px)`,
    marginLeft:isMobile  ? "0px": `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const useStyles = { backgroundColor: '#E2FBD7', color: '#1C1C1C', }

export default function   PersistentDrawerLeft() {
  const theme = useTheme();

  const [leftDrawerOpen, setLeftDrawerOpen] = React.useState(true);
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState(true);
  const [mobileLeftSideBar, setMobileLeftSideBar] = React.useState(false);




  const toggleMobileLeftSideBar = () => {
    setMobileLeftSideBar(!mobileLeftSideBar);
  };

  const toggleLeftDrawer = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };

  const handleDrawerOpen = () => {
    setRightDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setRightDrawerOpen(false);
  };


  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  const [username, setUsername] = React.useState('');
  const { currentUser } = useAuth();
  const [activeLink, setActiveLink] = React.useState(() => {
    // Attempt to retrieve state from localStorage
    const savedState = localStorage.getItem('Onboarding');
    return savedState ? JSON.parse(savedState) : "Onboarding";
  });
  const [leftClickedItem, setLeftClickedItem] = React.useState(() => {
    // Attempt to retrieve state from localStorage
    const savedState = localStorage.getItem('leftClickedItem');
    return savedState ? JSON.parse(savedState) : "";
  });

  const fetchUser = React.useCallback(async () => {
    const docRef = doc(database, "users", currentUser.uid);
    
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setUsername(doc.data().name);
  
      } else {
  
        console.log("No such document!");
      }
  });   
  }, [])

  React.useEffect(() => {
    fetchUser();
    localStorage.setItem('Onboarding', JSON.stringify(activeLink));
    localStorage.setItem('leftClickedItem', JSON.stringify(leftClickedItem));

  }, [activeLink,leftClickedItem,fetchUser]);

  // const [activeLink, activeLink] = React.useState("Onboarding"); // State to track active link
  const handleLinkClick = (link) => {
  
    setActiveLink(link)

  };
  function showDiv() {
    toggleMobileLeftSideBar()

  }

  const handleLeftClickedItem = (item) => {
 
    setLeftClickedItem(item);

  };








  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        className="topBar"
        position="fixed"
        theme={theme}
        open={leftDrawerOpen}
        isMobile={isMobile}
      >
        <Toolbar >
      
        <nav className='header-items' >
      
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignItems: "center", gap: "2rem" }}>
            <a className="nav-menu-links slack" rel="noreferrer" href="https://join.slack.com/t/dozerovc/shared_invite/zt-249zvkklx-tMFhYJMdTuuLnj1g0AzbQA" target="_blank">    Slack Community</a>

            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyItems: "center", justifyContent: "center" }}>
              <NavLink activeClassName="active" to="/membership" className="slack">
                <Button sx={{ color: "black", textTransform: "none", borderColor: "black" }} variant='outlined' >   Membership
                  <NorthEastIcon fontSize='small' sx={{
                    width: "0.9rem",
                    height: " 0.9rem"
                  }} />
                  </Button>

              </NavLink></div>
            <NavLink className="nav-menu-links slack user" activeClassName="active" to="/profile">
              {username}
            </NavLink>
           
          </div>
          <div className='nav-menu'>
        <Button variant='text' sx={{ textTransform: 'none', color: "black" }} onClick={showDiv}>Menu</Button>
      </div>
      </nav>
        </Toolbar>
      </AppBar>
     {isMobile ? null : <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          border: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={leftDrawerOpen}
      >
        
        <DrawerHeader className="offcanvas-header">
       
        <div className='nav-links'>
          <NavLink className="nav-menu-links logo" exact activeClassName="active" to="/" replace={true}>
            dozero
          </NavLink>
          
        </div>
        </DrawerHeader>


        <div className="side-nav-wrapper">


<div className="side-menu">

  <div className="side-menu-top">
    <h5 style={{ marginBottom: ".5rem" }}>Fundraising</h5>
    <Link style={{ textDecoration: 'none' }} to={`onboarding`}

      onClick={() =>{ handleLinkClick("Onboarding"); handleLeftClickedItem("Onboarding");} }

    >
      <div className={`side-link u-link ${activeLink === "Onboarding" ? "active" : ""}`}>

        Onboarding
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} to={`startupinformation`} replace  onClick={() =>{ handleLinkClick("startupinformation");  handleLeftClickedItem("Startup Information");}} >

      <div className={`side-link u-link ${activeLink === "startupinformation" ? "active" : ""}`}>
        Startup Information
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} to={`investmentround`} replace onClick={() => {handleLinkClick("investmentround"); handleLeftClickedItem("Investment Round");}} >
      <div className={`side-link u-link ${activeLink === "investmentround" ? "active" : ""}`}>

        Investment Round
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} to={`pitchstory`} replace onClick={() => {handleLinkClick("pitchstory");handleLeftClickedItem("Pitch Story ");}}  >

      <div className={`side-link u-link ${activeLink === "pitchstory" ? "active" : ""}`}>
        Pitch Story
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} to={`startupopportunity`} replace onClick={() => {handleLinkClick("startupopportunity");handleLeftClickedItem("Startup Opportunity");}}>

      <div className={`side-link u-link ${activeLink === "startupopportunity" ? "active" : ""}`}>
        Startup Opportunity
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} replace to={`emailblurp`} onClick={() => {handleLinkClick("emailblurp");handleLeftClickedItem("Email Blurp");}}>

      <div className={`side-link u-link ${activeLink === "emailblurp" ? "active" : ""}`}>
        Email Blurp
      </div>
    </Link>



    <Link style={{ textDecoration: 'none' }} to={`findinvestor`} replace onClick={() => {handleLinkClick("findinvestor");handleLeftClickedItem("Find Investor");}}>

      <div className={`side-link u-link ${activeLink === "findinvestor" ? "active" : ""}`}>

        Find Investors  

      </div>
    </Link>
  </div>

<div style={{ marginTop: ".5rem" }}>
<h5 >Metrics</h5>
<Link style={{ textDecoration: 'none' }} to={`kpi`} replace onClick={() => {handleLinkClick("kpi");handleLeftClickedItem("KPIs");}}>
    <div className={`side-link u-link ${activeLink === "kpi" ? "active" : ""}`}>
      KPIs
    </div>
  </Link>
  <div>
</div>
 

 
    <br />
    <h5 style={{ marginBottom: ".5rem" }}>Operations</h5>
    <Link style={{ textDecoration: 'none' }} to={`dataroom`} replace onClick={() => {handleLinkClick("dataroom");handleLeftClickedItem("Data Room");}}  >

      <div className={`side-link u-link ${activeLink === "dataroom" ? "active" : ""}`}>
        Data Room
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} to={`captable`} replace onClick={() => {handleLinkClick("cabtable");handleLeftClickedItem("Captable");}}>

      <div className={`side-link u-link ${activeLink === "captable" ? "active" : ""}`}>
        Captable
      </div>
    </Link>

    <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >
      {" "}
      <div className={` side-link disable-links u-link  ${activeLink === "docsign" ? "active" : ""}`} >
        Document Signing{" "} <span>
          {" "}
          <Chip label="coming soon" color="success" size="small" sx={useStyles} />

        </span>
      </div>
    </Link>



    <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >
      {" "}
      <div className={`side-link u-link ${activeLink === "dealroom" ? "active" : ""}`}>

        Deal Room{" "} <span>
          {" "}
          <Chip label="coming soon" color="success" size="small" sx={useStyles} />

        </span>

      </div>
    </Link>
  </div>

  <div>
    <h5 style={{ marginBottom: ".5rem" }}>Investors Report</h5>
    <Link style={{ textDecoration: 'none' }} replace className='disable-links'   >
      {" "}
      <div className={`side-link u-link ${activeLink === "investors" ? "active" : ""}`}>
        Investors {" "} <span>
          {" "}
          <Chip label="coming soon" color="success" size="small" sx={useStyles} />

        </span>
      </div>
    </Link>




    <Link style={{ textDecoration: 'none' }} replace className='disable-links'  >

      <div className={`side-link u-link ${activeLink === "updates" ? "active" : ""}`}>

        Updates{" "}
        <span>
          {" "}
          <Chip label="coming soon" color="success" size="small" sx={useStyles} />

        </span>

      </div>
    </Link>
  </div>
  <div className="side-menu-bottom">
    <h5 style={{ marginBottom: ".5rem" }}>Help</h5>

    <Link style={{ textDecoration: 'none' }} replace to={`talkwithexpert`}

      onClick={() => {handleLinkClick("talkwithexpert");handleLeftClickedItem("Talk With Expert");}}

    >
      <div className={`side-link u-link ${activeLink === "talkwithexpert" ? "active" : ""}`}>
        Talk With Expert
      </div>
    </Link>

  


    <div className="version">
      V1.0.1.3 beta
    </div>
  </div>
</div>

</div>




        {/* <div style={{display:"flex" ,flexDirection:"row",justifyContent:"end"}}>
        <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleLeftDrawer}
                  sx={{ mr: 2,  }}
                >
                  {leftDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
        </div> */}
       
      </Drawer>}
      <Main open={leftDrawerOpen} isMobile={isMobile}>
        <DrawerHeader />
        <AppMainContent open={rightDrawerOpen} isMobile={isMobile}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
         
              
           {!rightDrawerOpen  && !isMobile ?  <Button variant="outlined" style={{gridColumn: "span 12",marginLeft:"90%",marginBottom:"1rem"}} onClick={handleDrawerOpen} sx={{textTransform:"none",borderColor:"black",color:"black"}}>Artifacts</Button> : null}
              
            
             <Outlet/>
            </Grid>
          </Grid>
        </AppMainContent>

        <SideBarDemo open={mobileLeftSideBar} onClose={toggleMobileLeftSideBar} userName={username} />
        {isMobile ? null :    <Drawer
          sx={{
           
            width: drawerWidthRight,
            flexShrink: 0,
            position: isMobile ? "static" : "fixed",
            top: isMobile ? "auto" : "64px",
            "& .MuiDrawer-paper": {
              width: drawerWidthRight,
              background: "rgba(28, 28, 28, 0.02)",
              top: "64px",
            },
          }}
          variant="persistent"
          anchor={isMobile ? "bottom" : "right"}
          open={rightDrawerOpen}
          className="right-drawer"
        >
          <Artifacts visbile={rightDrawerOpen} onClose={handleDrawerClose} selectedLeftNavItem={leftClickedItem}
           />
        </Drawer> }
      

      </Main>
      
    </Box>
  );
}
