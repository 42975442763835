import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { Button, Stack } from '@mui/material';
import LabelBackButton from '../../../component/LabelBackButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function InstrutmentType() {

  const navigate = useNavigate();

  const location = useLocation();

      const radioOptions = ["A SAFE (Simple Agreement for Future Equity) is a contract that converts into equity in the future. Based on standard YC SAFEs.","Equity rounds set a valuation at the time of investment and defined ownership and control for stakeholders. Requires existing docs."];
      


      console.log(location.state.roundStage);
      const headingText = [<b>SAFE Round</b>,<b>Equity</b>];

      const [instType, setInstType] = useState('');


    
      const handleRadioChange = (event) => {
        setInstType(event.target.value);
      };
      const handleListItemClick = (value) => {
        setInstType(value);
      };
   
    
    
      
    
    
      return (
        <>
    
        <LabelBackButton title={"Instrument Type"} />
        <List dense sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
        {radioOptions.map((value,index) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          const isSelected = instType === value;
          const borderStyle = isSelected ? '2px solid black' : '1px solid grey';
    
          return (
            <ListItem
            
              onClick={()=>handleListItemClick(value)}
              sx={{ border: borderStyle, borderRadius: "10px", marginTop: '1rem' }}
              key={value}
            >
              <ListItemButton >
                <Stack>
                <ListItemText style={{fontFamily:"boldsans"}} primary={headingText[index]}/>
                <ListItemText id={labelId} primary={`${value}`} />
                </Stack>
          
       
    
              <FormControl>
                <Radio
                  sx={{ 
                    '&.Mui-checked': {
                      color: 'black', // checked color
                    },}}
                  checked={isSelected}
                  onChange={handleRadioChange}
                  value={value}
                />
              </FormControl>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <br/>
  
      <Button onClick={()=>navigate("ts",{ state: { roundStage: location.state.roundStage,instType: instType,} })}  variant='outlined' sx={{  color: "black", borderColor: "black", textTransform: "none" ,width:"20%"}} >Continue</Button>

    
      </>
        
      );
    };
    

