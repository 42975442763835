import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import LinearWithValueLabel from '../LinearProgressBar/LinearProgressWithLabel';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './UploadFileDialog.css'
import { doc, addDoc, collection } from "firebase/firestore";
import { storage, database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';


const BootstrapDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        padding: theme => theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme => theme.spacing(1),
    },
}));


const validateForm = (data) => {
    const errors = {};


    // Validate the "email" field
    if (!data.name) {
        errors.email = "File Name is required";
    }
    // Validate the "password" field
    if (!data.file) {
        errors.password = "Please choose file";
    }



    return Object.keys(errors).length === 0; // Return true if there are no errors
};


// program to convert first letter of a string to uppercase
function smallFirstLetter(str) {
    str = str.split(' ')[0];
    console.log("str  " + str);
    // converting first letter to uppercase
    const small = str.charAt(0).toLowerCase() + str.slice(1);
    console.log("small  " + small);



    return small;
}


function getCurrentDate() {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${day}/${month}/${year}`;
    console.log(currentDate);
    return currentDate;
}

export default function UploadFileDialog({ open, onClose, title, fileTitle, fileType }) {
    const [fileName, setFileName] = useState("");
    const [name, setName] = useState("");
    const [file, setFile] = useState(null);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [disableUploadbutton, setDisableUploadButton] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();




    useEffect(() => {
        if (!open) {
            setName('');
            setFileName('');
            setFile(null);
            setFileUploadProgress(0)
            setDisableUploadButton(false)

        }
    }, [open]);



    const handleDialogClose = (event, reason) => {
        //prevent user to close the dialog by pressing on the page.
        if (reason && reason === "backdropClick") {
            return;
        }
        setFileName("");
        setName("")
        setFile("")
        setFileUploadProgress(0);
        setDisableUploadButton(false);
        onClose();

    };

    const hiddenFileInput = useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleFileUploadToFirebaseStorage = () => {
        if (file) {
            setDisableUploadButton(true);
            let currenttitle = title;
            let storagetitle = title;

            let colName = "dataRoom";
            if(title==="3 minutes Pitch (Video)"){
                currenttitle="pitch"
                storagetitle="startupopportunity/pitch"

                colName="startupopportunity";
            }else if(title==="3 minutes on UX (Video)"){
                currenttitle="ux"
                colName="startupopportunity";
                storagetitle="startupopportunity/ux";


            }else if(title==="3 minutes on Tech (Video)"){
                currenttitle="tech"
                colName="startupopportunity";
                storagetitle="startupopportunity/tech"

            }
            else if(title==="Pitch Story"){
                currenttitle="data"
                colName="pitchStory";
                storagetitle="pitchstory"



            }
            
            
            else{
          currenttitle = title.split(' ')[0].toLowerCase();
          storagetitle= currenttitle;
            }
        

            // Create a storage reference to the file
            const filePath = `users/${currentUser.email}/${storagetitle}/${file.name}_${uuidv4()}`;
            const storageRef = ref(storage, filePath);
            const uploadTask = uploadBytesResumable(storageRef, file,);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    setFileUploadProgress(progress);

                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'success':

                            console.log('Upload is completed');
                            break;
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                            console.log("nothn")
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;



                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                            console.log("noth");
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);


                        addDoc(collection(database, colName, currentUser.uid, smallFirstLetter(currenttitle)), {
                            fileName: name,
                            fileUrl: downloadURL,
                            createdAt: getCurrentDate()
                        }).then(

                        );


                    });

                    document.getElementsByClassName("upload-file-msg")[0].style.display = "block";
                    setDisableUploadButton(false);

                    handleDialogClose()
                }

            );


        } else {
            console.error('No file selected');
            setDisableUploadButton(false);
        }
    };



    const onDataSubmitted = async (data, e) => {
        e.preventDefault();
        const isValid = validateForm(data);
        console.log("isValid" + isValid);
        if (isValid) {
            handleFileUploadToFirebaseStorage()
        }
    }


    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded);
        setFileName(fileUploaded.name);
        setFile(fileUploaded);
    };
    const { currentUser } = useAuth()

    return (

        <BootstrapDialog
            onClose={handleDialogClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            // open={closeDialog ?false: openUploadDialog }
            disableBackdropClick
            fullWidth
            borderRadius="10px"
            maxWidth="xs"
        >
            <DialogTitle sx={{ marginLeft: 2, p: 2 }} id="customized-dialog-title">
                {"Add " + title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleDialogClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 12,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>{fileTitle}</DialogContentText>
                <TextField
                    type="text"
                    placeholder="Give a name for this deck"
                    fullWidth
                    value={name}
                    {...register("name", {
                        required: "File Name is required",
                    })}
                    onChange={(e) => setName(e.target.value)}

                />
                {errors.name && <p className="error-text">{errors.name.message}</p>}

            </DialogContent>
            <DialogContent>
                <DialogContentText>{fileType}</DialogContentText>
                <TextField
                    onClick={handleClick}
                    type="text"
                    placeholder="Upload document"
                    value={fileName}
                    fullWidth
                    inputProps={
                        { readOnly: true, }
                    }
                    {...register("file", {
                        required: "Please choose file",
                    })}
                    

                />
                <input
                    type="file"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }} />
                {errors.file && <p className="error-text">{errors.file.message}</p>}

            </DialogContent>
            <div style={
                { display: 'flex', justifyContent: 'center' }
            }> <LinearWithValueLabel progress={fileUploadProgress} />   </div>

            <DialogActions style={{ justifyContent: "space-between" }}>
                <Button disabled={disableUploadbutton} sx={{ marginLeft: "20px", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} variant='outlined' autoFocus 
                onClick={ handleSubmit(onDataSubmitted)}>
                    Upload

                </Button>
                <div className='upload-file-msg'>This file is uploaded successfully</div>
            </DialogActions>
        </BootstrapDialog>


    )
}
