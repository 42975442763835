import { Button, FormControl, FormGroup, FormLabel, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LabelBackButton from '../../../component/LabelBackButton';
import { getValue } from '@testing-library/user-event/dist/utils';

export default function TargetSize() {
    const { control, handleSubmit,
        watch, formState: { errors }, setValue,getValues } = useForm();
    const location = useLocation();
    // const [targetSize, settargetSize] = useState('');

    const navigate = useNavigate();

 
    console.log("roundStage" + location.state.roundStage);
    console.log("instrumentType" + location.state.instType);
    console.log("targetSize" + location.state.targetSize);
    const targetSize = watch('targetSize'); // Replace 'fieldName' with the actual name of your field



    const amounts = ["5", "10", "15", "20"];

    useEffect(()=>{
        if(location.state != null){

        //   settargetSize(location.state.targetSize);
          setValue("targetSize",location.state.targetSize)
        }
      },[])
    const onSubmit = (data) => {
        console.log(data, errors);
        if (Object.keys(errors).length === 0) {
          

        } else {
            // Handle the display of errors

            console.log("errror " + errors);
        }
    };


    return (
        <div>
            <LabelBackButton title={"Target Size"} />
            <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl  sx={{ width: "52.5%", marginTop: "3rem" }}>
                <Controller
                    name="targetSize"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Target Size',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Invalid round size. Please enter digits only.',
                          },

                    }}
                    render={({ field }) => <TextField
                    
                   
                        InputProps={{
                            startAdornment: <InputAdornment position="start">


                            <p style={{ fontSize: "1rem"}}>$ </p>




                        </InputAdornment>,
                            

                               
                            endAdornment:

                                <InputAdornment position="end">


                                    <p style={{ fontSize: "0.7rem", background: "#F2F2F0", padding: "5px", borderRadius: "5px" }}>($) USD</p>




                                </InputAdornment>
                        }}
                        {...field} fullWidth type="text" placeholder="Round Size"

                        label="Round Size"

                    />}
                />

                {errors.targetSize && <p className="error-text">{errors.targetSize.message}</p>}

            </FormControl>
            </form>
            <Stack sx={{ marginTop: "1rem" }} direction={"row"}>
                {amounts.map((amount) => {
                    return <Button variant='outlined' onClick={() => setValue("targetSize", (amount * 1000000).toLocaleString())} sx={{ width: "12%", marginRight: "1rem", background: "#F2F2F0", color: "black", border: "none" }}>{"$" + amount + "M"}</Button>
                })}

            </Stack>

            <Button
                variant='outlined'  type='submit' disabled={!targetSize} onClick={() =>
                {
                     navigate("eq", { state: { roundStage: location.state.roundStage, instType: location.state.instType, targetSize: targetSize.replace(/,/g, '') } })}} sx={{ marginTop: "5rem", color: "black", borderColor: "black", textTransform: "none", width: "20%" }} >Continue</Button>


        </div>
    )
}
