import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const EmailResetDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetEmail = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth,email)
      .then(() => {
        console.log("Email reset link sent successfully");
      })
      .catch((error) => {
        console.error(error.message);
      });

    onClose(); // Close the dialog after sending the reset email
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To reset your email, please enter your  email address below. We will send a reset link to this email.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleResetEmail} color="primary">
          Reset Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailResetDialog;
