
import React, { useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Button, IconButton, Stack } from '@mui/material';
import { CloudCircleOutlined, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import PageTopComponent from '../../component/PageTopComponent/PageTopComponent';
import { useCollection } from 'react-firebase-hooks/firestore';
import "./Captable.css"
import DeleteFileDialog from '../../component/DeleteFileDialog';
import {  updateDoc, increment, deleteDoc, doc, collection, onSnapshot } from "firebase/firestore";
import { database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext'
import CaptableEditForm from './CaptableEditForm';
import CaptableFormNew from './CaptableFormNew';
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import SideNavRight from '../../component/SideNavRght/SideNavRight';


const Captable = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
  const { currentUser } = useAuth();
  const [artifacts, setArtifacts] = useState(false);

  const [closeDialog, setCloseDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [isArtifactsVisible, setIsArtifactVisible] = useState("hidden");


  const [documentID, setDocumentID] = useState('');

  const [savedName, setSavedName] = useState('');
  const [savedEquity, setSavedEquity] = useState('');
  const [totalEquity, setTotalEquity] = useState('');

  const [dialogOpen, setDialogOpen] = useState(false);



 
  function posToNeg(num) {
    return -Math.abs(num);
  }





  async function deleteDocument(docID, equity) {
    console.log("docID " + docID);
    const docRef = doc(database, "captable", currentUser.uid, "shareholders", docID);
    const docEquity = doc(database, "captable", currentUser.uid);

    await updateDoc(docEquity, {
      totalEquity: increment(posToNeg(equity))
    })

    console.log("docRef " + docRef.path);

    await deleteDoc(docRef);

  }







  const handleDialogOpen = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }

    setCloseDialog(true);

  };

  const handleDialogClose = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
      return;
    }
   
    setCloseDialog(false);

  };



  const handleListItemClick = (docID,equity, shareholder) => {
    setSavedEquity(equity)
    setSavedName(shareholder)
    setDocumentID(docID)
    setCloseDialog(true);
  };


  const deleteItemhandle = (docID, equity) => {
    setSavedEquity(equity)
    setDocumentID(docID)
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    
    setOpenEditDialog(false);

  };





  const fetchEquity = useCallback(async () => {
    const docRef = doc(database, 'captable', currentUser.uid)

    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setTotalEquity(100 - doc.data().totalEquity);

      } else {
        setTotalEquity(100);
      }

    })



  }, [totalEquity]);



  useEffect(() => {
    fetchEquity();
  }, [totalEquity])


  let myRef = collection(database, 'captable', currentUser.uid, "shareholders")


  const [value, loading, error] = useCollection(
    myRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );


  return (
    <>

<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",justifyItems:"space-between"}}>
    <PageHeadingLabel headingText={"Captable"}/>
    
    <Button variant='outlined'  disabled={totalEquity == 0} onClick={handleDialogOpen} sx={{color:"black",borderColor:"black"  ,textTransform:"none" }} >{ isMobile ? "Add" : "Add Shareholder"}</Button>
    </div>
   
      <CaptableFormNew open={closeDialog} onClose={handleDialogClose} totalEquity={totalEquity}
       sharedholderName={savedName} sharedholderEquity={savedEquity} allEquity={totalEquity} docID={documentID} />

      {/* <CaptableEditForm open={openEditDialog} onClose={handleCloseDialog} 
      sharedholderName={savedName} sharedholderEquity={savedEquity} allEquity={totalEquity} docID={documentID} /> */}

      <DeleteFileDialog
        deleteText={"Are you sure you want to delete this data? This action cannot be undone."}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onDelete={() => deleteDocument(documentID, savedEquity)}
      />

        

 

{error && <strong>Error: {JSON.stringify(error)}</strong>}
{loading && <span>Checking data...</span>}
{value && (
  <span>
    {value.docs.map((docData) => (
      <React.Fragment key={docData.id}>
        <div style={{ paddingLeft: "15px", paddingRight: "25px", marginTop: "20px", height: "80px", border: "1px solid #1C1C1C", borderRadius: "10px", display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {!docData.data().photoURL ? null : <div style={{ display: "inline", alignItems: "center", justifyContent: "center" }}>
              <img
                style={{ display: "inline", marginRight: "1rem", maxWidth: "47px", maxHeight: "47px" }}
                className="user-profile-pic"

                src={docData.data().photoURL ? docData.data().photoURL : process.env.PUBLIC_URL + "/pic/user_profile.png"}
                alt="profile" />

            </div>}


            <div> <p className='cap-title-text'>{docData.data().sharedholderName}</p>
              <p className='cap-data-date'>{docData.data().equity + "%"}</p></div>
          </div>


          <div><IconButton onClick={() => {
            handleListItemClick(docData.id,docData.data().equity, docData.data().sharedholderName);
          }}> <EditIcon sx={{ color: "black", border: "1px solid black", borderRadius: "5px" }} /></IconButton>
            <IconButton onClick={() => deleteItemhandle(docData.id, docData.data().equity)} ><Delete sx={{ color: "#FB9B9B", border: "1px solid #FB9B9B", borderRadius: "5px" }} /></IconButton></div>

        </div>

      </React.Fragment>

    ))}
  </span>
)}
 
      

       
      
       
       

     
    </>

  )
}

export default Captable