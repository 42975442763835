import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./profile.css"
import { Avatar, Button, FormControl, FormGroup, FormLabel, Skeleton, Stack, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth, database } from '../../firebase';
import { updateDoc, doc, getDoc, onSnapshot, } from "firebase/firestore";
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import { useAuth } from '../../hooks/AuthContext';
import 'react-circular-progressbar/dist/styles.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import EmailResetDialog from '../../component/EmailResetDialog';
import Swal from 'sweetalert2';
import { useInputDebounce } from '../../hooks/useInputDebounce';
import ProfileAvatar from '../../component/ProfileAvatar/ProfileAvatar';
import UploadDialog from '../../component/UploadDialog/UploadDialog';

const Profile = () => {
  const {control, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState('');
  const [dataLoading, setDataLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const [open, setOpen] = useState(false);


  const [input, setInput] = useInputDebounce();



  const hiddenFileInput = useRef(null);


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUploadDialog =( event, reason) => {
    
      if (reason && reason === "backdropClick") {
        return;
      }
     
  
  
  
    setOpen(open=>!open);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // const handleChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
  //   if (selectedFile) {
  //     setFileURL(URL.createObjectURL(selectedFile));
  //   }
  //   handleFileUploadToFirebaseStorage()

  // };


  // const handleFileUploadToFirebaseStorage = () => {
  //   if (file) {

  //     // Create a storage reference to the file
  //     const filePath = `users/${currentUser.email}/profile/${file.name}_${uuidv4()}`;
  //     const storageRef = ref(storage, filePath);
  //     const uploadTask = uploadBytesResumable(storageRef, file,);

  //     // Listen for state changes, errors, and completion of the upload.
  //     uploadTask.on('state_changed',
  //       (snapshot) => {
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

  //         //  setFileUploadProgress(progress);

  //         console.log('Upload is ' + progress + '% done');
  //         switch (snapshot.state) {
  //           case 'success':

  //             console.log('Upload is completed');
  //             break;
  //           case 'paused':
  //             console.log('Upload is paused');
  //             break;
  //           case 'running':
  //             console.log('Upload is running');
  //             break;
  //           default:
  //             console.log("nothn")
  //         }
  //       },
  //       (error) => {
  //         // A full list of error codes is available at
  //         // https://firebase.google.com/docs/storage/web/handle-errors
  //         switch (error.code) {
  //           case 'storage/unauthorized':
  //             // User doesn't have permission to access the object
  //             break;
  //           case 'storage/canceled':
  //             // User canceled the upload
  //             break;



  //           case 'storage/unknown':
  //             // Unknown error occurred, inspect error.serverResponse
  //             break;
  //           default:
  //             console.log("noth");
  //         }
  //       },
  //       async () => {
  //         // Upload completed successfully, now we can get the download URL
  //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //           console.log('File available at', downloadURL);

  //           addToFirestore("profileImageUrl", downloadURL);



  //         });



  //       }

  //     );


  //   } else {
  //     console.error('No file selected');
  //   }
  // };

  function showPermissionDeniedError() {
    return Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: "Permission Denied.Contact Support team",
      showConfirmButton: true,

    })

  }

  const fetchUser = useCallback(async () => {
    try {
      const docRef = doc(database, "users", currentUser.uid);
      // const docSnap = await getDoc(docRef);
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setFileURL(docSnap.data().profileImageUrl)
          setValue("name", docSnap.data().name);
          setValue("linkedinURL", docSnap.data().likedinProfileUrl);
          setValue("positionInCompany",docSnap.data().positionInCompany);
          setDataLoading(false)
  
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
    });   
    
    } catch (e) {
      console.log("profile " + e);
      showPermissionDeniedError();
    }


  }, [setValue,currentUser.uid])



  useEffect(() => {
    fetchUser();
  }, [fetchUser])


  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const addToFirestore = async (valueKey, value) => {
    console.log("calling addtoFirestore");
    // Add your Firestore logic here
    const profileRef = doc(database, "users", currentUser.uid);

    try {
      await updateDoc(profileRef, {
        [valueKey]: value,

      })
    } catch (e) {
      showPermissionDeniedError();
    }

  };




  const debouncedAddToFirestore = debounce(addToFirestore, 1000);




  const handleLogout = () => {

    signOut(auth).then(() => {
      // Sign-out successful.
      navigate("/",{replace:true});
      console.log("Signed out successfully")
    }).catch((error) => {
      // An error happened.
    });
  }






  return (
    <>
      <PageHeadingLabel headingText={"Profile"} />
      <div className=''>

        <UploadDialog open={open} onClose={handleUploadDialog} isProfilePic={true} />

        <div style={{ marginTop: "2.125rem", marginLeft: "1.5rem", fontFamily: "dmsans", fontSize: "0.875rem" }} >
          Personal Photo
        </div>


        <div className="user-image">
          {dataLoading ? (
            <Skeleton animation="wave" variant="circular" sx={{ width: "140px", height: "140px" }} />
          ) : 
          
          <ProfileAvatar onEdit={handleUploadDialog} fileURL={fileURL ? fileURL : process.env.PUBLIC_URL + "/pic/user_profile.png" } />

          // <Avatar sx={{ width: 120, height: 120 }} src={fileURL ? fileURL : process.env.PUBLIC_URL + "/pic/user_profile.png"} />
          // <img
          //   onClick={handleClick}
          //   className="user-profile-pic"
          //   src={fileURL ? fileURL : process.env.PUBLIC_URL + "/pic/user_profile.png"}

          //   alt="profile"

          // />
          
          }

          {/* <input
            type="file"
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} /> */}

          <span style={{ marginLeft: "1rem" }}>Upload photo</span>
        </div>

        <Stack style={{ width: "25rem", marginTop: "2rem" }}>

          <FormControl>
            {!dataLoading ? <FormGroup >
              <FormLabel sx={{

                fontFamily: "boldsans",
                fontSize: "0.9rem",
                color:
                  "black"
              }} >First and Last Name</FormLabel>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Name is required',
                  minLength: {
                    value: 2,
                    message: "Name should be at least 2 characters long",
                  },
                }}
                render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="First and Last Name"
                  onKeyUp={() => debouncedAddToFirestore("name", field.value)}

                />}
              />



              {errors.fullName && <p className="error-text">{errors.fullName.message}</p>}
            </FormGroup> : <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={"25rem"} height={60} />
            }

            <br />
            {!dataLoading ? <FormGroup >
              <FormLabel sx={{

                fontFamily: "boldsans",
                fontSize: "0.9rem",
                color:
                  "black"
              }}> LinkedIn Profile</FormLabel>

              <Controller
                name="linkedinURL"
                control={control}
                defaultValue=""
                rules={{
                  required: 'LinkedIn Profile is required',

                }}
                render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="https://www.linkedin.com/in/elonmusk"
                  onKeyUp={() => debouncedAddToFirestore("likedinProfileUrl", field.value)}

                />}
              />

              {errors.linkedinURL && <p className="error-text">{errors.linkedinURL.message}</p>}

            </FormGroup> : <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={"25rem"} height={60} />}

            <br />

            {!dataLoading ? <FormGroup >
              <FormLabel sx={{

                fontFamily: "boldsans",
                fontSize: "0.9rem",
                color:
                  "black"
              }}> Position in the Company</FormLabel>
            
               <Controller
                name="positionInCompany"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Role is required',

                }}
                render={({ field }) => <TextField {...field} fullWidth type="text" placeholder="Role in the company"
                  onKeyUp={() => debouncedAddToFirestore("positionInCompany", field.value)}

                />}
              />
              {errors.positionInCompany && <p className="error-text">{errors.positionInCompany.message}</p>}

            </FormGroup> : <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={"25rem"} height={60} />}


          </FormControl>


        </Stack>
      </div>
      <br />

      <div className="profile-button"><Button sx={{ color: 'rgba(28, 28, 28, 0.25)', textTransform: "none", fontSize: "0.9rem" }} onClick={handleLogout}>Logout</Button></div>
      <div className="profile-button" ><Button onClick={handleOpenDialog} sx={{ color: 'rgba(28, 28, 28, 0.25)', textTransform: "none" }}>Change Password </Button></div>

      <div className="profile-button"><Button sx={{ color: 'rgba(28, 28, 28, 0.25)', textTransform: "none" }}>Delete Account </Button></div>

      <EmailResetDialog open={isDialogOpen} onClose={handleCloseDialog} />

    </>



  )
}

export default Profile