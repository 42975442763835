import { IconButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BackButton({customFunction}) {
    const navigate = useNavigate()
  return (
    <IconButton  sx={{ border:"1px solid black",borderRadius:"20%",width: "2.375rem",
    height: "1.6875rem"}} onClick={()=>{
        if(customFunction){
            customFunction()

        }
        navigate(-1)
        }}>
    <ArrowBackIosIcon fontSize='small' style={{
      
        display: "flex",
        marginLeft:"0.2rem",
        fontSize:"0.8rem",
        flexDirection: "column",
        justifyContent: "center",
        alignContent:"center",
        alignItems:"center",
        flexShrink: 0
    }} />
    </IconButton>
  )
}
