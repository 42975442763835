import React, { useCallback, useEffect, useState } from 'react';
import PageHeadingLabel from '../../component/PageHeadingLabel/PageHeadingLabel';
import { Box, Button, Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext';
import DeleteFileDialog from '../../component/DeleteFileDialog';

const Investment = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();


  const investmentKeys = ["Investment Round Type", "Monetary Unit", "Round Amount", "Equity", "Pre-Money Valuation", "Post-Money Valuation"];
  const [investmentValues, setInvestmentValues] = useState([]);
  const [investment, setInvestment] = useState([]);

  const { currentUser } = useAuth();

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const fetchInvestment = useCallback(async () => {

    const docRef = doc(database, "investmentRound", currentUser.uid);

try{
  onSnapshot(docRef, (doc) => {
    if (doc.exists()) {


      const investmentData = doc.data();
      console.log("insty " + doc.data().instrumentType);
      setInvestment(investmentData)
      setInvestmentValues([
        investmentData.roundType,
        "$",
        investmentData.targetSize,
        investmentData.equity,
        investmentData.preMoneyValuation,
        investmentData.postMoneyValuation,
      ]);



    }
  }

  )

}catch(e){
  
}

  
  }





    , [currentUser.uid]);

  useEffect(() => {
    fetchInvestment();
  }, [fetchInvestment]);


  async function deleteDocument() {
    setInvestment([]);
    setInvestmentValues([]);
    const docRef = doc(database, "investmentRound", currentUser.uid);
    await deleteDoc(docRef);
   

  }


  return (
    <>
      {console.log("investment is empty" + isEmpty(investment))}

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", justifyItems: "space-between" }}>
        <PageHeadingLabel headingText={"Round Data"} />
        {isEmpty(investment) ? <Link to={"roundstage"}>
          <Button variant='outlined' sx={{ color: "black", borderColor: "black", textTransform: "none" }} >{isMobile ? "Create" : "Create investment round"}</Button>
        </Link> : null}
      </div>
      {isEmpty(investment) ? <div>You have not yet set up your investment round.</div> : null}

      {!isEmpty(investment) ? <List dense sx={{ width: '100%', bgcolor: 'background.paper', border: "1px solid black", borderRadius: "10px", marginTop: "2rem" }}>
        {investmentKeys.map((text, index) => (
          <div key={text}>
            <ListItem sx={{ padding: "10px" }}>
              <ListItemText primaryTypographyProps={{
                color: '#1C1C1C',

                fontFamily: 'mdsans',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }} primary={text} secondaryTypographyProps={{
                color: '#1C1C1C',

                fontFamily: 'dmsans',
                fontSize: '0.6875rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }} secondary={investmentValues[index] || ''} />
            </ListItem>
            {index < investmentKeys.length - 1 && <Divider sx={{ color: "black", borderWidth: '1px' }} />}
          </div>
        ))}
      </List>

        : null}
      {!isEmpty(investment) ? <Stack sx={{ marginTop: "2rem" }} direction={"row"} spacing={2} justifyContent="flex-end">
        <Button variant='outlined'
        disabled
          onClick={() => {
            navigate("roundstage", { state: { roundStage: investment.roundType ,instType:investment.instrumentType,roundSize:investment.targetSize.toLocaleString(),equity:investment.equity} })
          }}

          sx={{ textTransform: "none", borderRadius: "5px", borderColor: "black", color: "black", fontFamily: 'dmsans', fontSize: '0.9rem' }}>Edit Round</Button>
        <Button variant='outlined' onClick={() => setDialogOpen(true)} sx={{ textTransform: "none", borderRadius: "5px", borderColor: "black", background: "#FB9B9B", color: "white", fontFamily: 'dmsans', fontSize: '0.9rem' }}>Delete Round</Button>

      </Stack> : null}
      <DeleteFileDialog
        deleteText={"Are you sure you want to delete this data? This action cannot be undone."}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onDelete={() => deleteDocument()}
      />

    </>
  )
}

export default Investment;
