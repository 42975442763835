import React, { useEffect, useState } from 'react';
import { collection, query, onSnapshot,deleteDoc } from "firebase/firestore";
import { database } from '../../firebase';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import "./KPIGroup.css"
import { useAuth } from '../../hooks/AuthContext';
import DeleteFileDialog from '../../component/DeleteFileDialog';

import { updateDoc, doc } from "firebase/firestore";
import KPiEditForm from './KPiEditForm';
import dayjs from 'dayjs';



export default function KpiGroup () {

    const { currentUser } = useAuth();

    const [data, setData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);

    const [documentID, setDocumentID] = useState('');
    const [kpiName, setKPIName] = useState('');
    const [KpiValue, setKPIValue] = useState('');
    const [KpiDate, setKPIDate] = useState('');






    async function deleteDocument() {
        const docRef = doc(database, "kpis", currentUser.uid, "data", documentID);
        await deleteDoc(docRef);
    
      }

      const handleDialogClose = (event, reason) => {
        //prevent user to close the dialog by pressing on the page.
        if (reason && reason === "backdropClick") {
            return;
        }
        setCloseDialog((closeDialog)=> !closeDialog);
      
    
    };


      const handleListItemClick = (docID,kpiName,kpiValue, kpiDate) => {
        console.log("kpiDatesss " + kpiDate);
        setKPIName(kpiName)
        setKPIValue(kpiValue)
        setDocumentID(docID)
        setKPIDate(dayjs(kpiDate))
        setCloseDialog(true);
      };
    
    
      const deleteItemhandle = (docID) => {
        setDocumentID(docID)
        setDialogOpen(true);
      };  

    useEffect(() => {

        const fetchData = async () => {

            const startDate = new Date();
            startDate.setMonth(0); // First month of the year
            startDate.setDate(1); // First day of the month
            const endDate = new Date();
            endDate.setMonth(11); // Last month of the year
            endDate.setDate(31); // Last day 

            const q = query(collection(database, "kpis", currentUser.uid, "data"),
            
            );


            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const groupedData = {};
                querySnapshot.forEach((doc) => {
                    const timestamp = doc.data().createdAt.toDate();
                    const monthKey = `${new Intl.DateTimeFormat('en-US', {
                        month: 'long'
                    }).format(timestamp)}, ${timestamp.getFullYear()}`;
    
                    if (!groupedData[monthKey]) {
                        groupedData[monthKey] = [];
                    }
    
                    groupedData[monthKey].push({ id: doc.id, data: doc.data(),date:timestamp});
                }); 
    
                setData(groupedData);
            });
    
            // Clean up the listener when the component unmounts
            return () => unsubscribe();
        };

        fetchData();
    }, []);





    return (
    
       <>
            <DeleteFileDialog
        deleteText={"Are you sure you want to delete this data? This action cannot be undone."}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onDelete={() => deleteDocument()}
      /> 
        <KPiEditForm onClose={handleDialogClose} open={closeDialog} kpiName={kpiName} kpiValue={KpiValue} kpiDate={KpiDate} docID={documentID}/>

       <span>
            {Object.keys(data).map((date) => (
                <div key={date} style={{marginTop:"2rem"}}>
                    <div className='month-year-format'>{date.toLocaleString('default', { month: 'long' })
                    }</div>

                    {data[date].map((item) => (
                        <div style={{ paddingLeft: "25px", paddingRight: "25px", marginTop: "10px", height: "80px", border: "1px solid #1C1C1C", borderRadius: "10px", display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div> <p className='title-text'>{item.data.kpiName}</p>
                                <p className='data-date'>{item.data.kpiValue}</p></div>

                            <div>
                                <IconButton onClick={()=>handleListItemClick(item.id,item.data.kpiName,item.data.kpiValue,item.date)} >
                                    <Edit sx={{ color: "black", border: "1px solid black", borderRadius: "5px" }} />
                                </IconButton>
                                <IconButton   onClick={()=>deleteItemhandle(item.id)}>
                                    <Delete sx={{ color: "#FB9B9B", border: "1px solid #FB9B9B", borderRadius: "5px" }} />
                                </IconButton></div>

                        </div>
                    ))}

                </div>
            ))}
      </span>
       </>
           

    
    
    );
};
