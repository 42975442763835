
import {  Button, FormControl, FormGroup, FormLabel } from '@mui/material'
import React, {  useEffect, useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useForm,Controller } from 'react-hook-form';
import "./Captable.css"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {getDoc,setDoc,updateDoc,increment,doc, addDoc,collection } from "firebase/firestore"; 
import { storage,database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext'
import { v4 as uuidv4 } from 'uuid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme => theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
      padding: theme => theme.spacing(1),
    },
  }));

export default function CaptableEditForm({open,onClose,sharedholderName,sharedholderEquity,allEquity,docID}) {


    const {         control,reset,
      register, handleSubmit, formState: { errors },setValue } = useForm();
    const min = 0;
    const { currentUser } = useAuth();
  
   
    const [disableUploadbutton, setDisableUploadButton] = useState(false);

    const [editName, setEditName] = useState('');
    const [editEquity, setEditEquity] = useState('');
    const [totalEquity, setTotalEquity] = useState('');
    const [documentID, setDocumentID] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const [fileUploadProgress, setFileUploadProgress] = useState(0);

    const hiddenFileInput = useRef(null);


    function posToNeg(num) {
      return -Math.abs(num);
    }
  
  
 


    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };
  
  
    const handleChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      if (selectedFile) {
        setFileName(URL.createObjectURL(selectedFile));
      }
  
    };
    console.log("total equity is 100% " + allEquity );

 
  
  
    useEffect(() => {

      setValue("name", sharedholderName || ""); // Set default value for "name" input
    setValue("equity", sharedholderEquity || ""); // Set default value for "equity" input

      setDocumentID(docID);
      if(allEquity==0){
        setValue("totalEquity", sharedholderEquity || ""); // Set default value for "equity" input

        // setTotalEquity(sharedholderEquity);

      }else{
        const eq = allEquity+sharedholderEquity;

        setValue("totalEquity", eq || ""); // Set default value for "equity" input

        // setTotalEquity(allEquity+sharedholderEquity);
      }
    },[allEquity,sharedholderName,sharedholderEquity,docID,setValue] )
  



    function getCurrentDate() {
        const date = new Date();
    
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
    
        let currentDate = `${day}/${month}/${year}`;
        console.log(currentDate);
        return currentDate;
      }
    
    
      const validateForm = (data) => {
        const errors = {};
    
    
        // Validate the "name" field
        if (!data.name) {
          errors.name = "Name is required";
        }
    
        // Validate the "equity" field
        if (!data.equity) {
          errors.equity = "Equity is required";
        }
    
    
    
        return Object.keys(errors).length === 0;
      };



      const  handleFileUploadToFirebaseStorage =async () => {
        if (file) {
            setDisableUploadButton(true);
            // Create a storage reference to the file
            const storageRef = ref(storage, `users/${currentUser.email}/${"captable"}/${file.name}"_"${uuidv4()}`);
    
            const uploadTask = uploadBytesResumable(storageRef, file,);
    
    
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    
                    setFileUploadProgress(progress);
    
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'success':
    
                            console.log('Upload is completed');
                            break;
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                            console.log("nothn")
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
    
    
    
                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                            console.log("noth");
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                        console.log('File available at', downloadURL);
    
                        const docEquity = doc(database, "captable", currentUser.uid);
    
    
                       
    
                        if(allEquity==0 &&  editEquity < sharedholderEquity ){
                           const remainingEquity = sharedholderEquity - editEquity;
                         await updateDoc(docEquity,{
                           totalEquity:increment(posToNeg(remainingEquity))
                         })
                 
                        }else{
                          await setDoc(docEquity,{
                            totalEquity:increment(editEquity)
                          })
                        }
    
    
                     
                        await updateDoc(doc(database, "captable", currentUser.uid, "shareholders",documentID), {
                          sharedholderName: editName,
                          equity: editEquity,
                          photoURL:downloadURL,
                          createdAt: getCurrentDate()
                        });
    
                    });
                   
              
    
                    document.getElementsByClassName("upload-file-msg")[0].style.display = "block";
                    setDisableUploadButton(false);
    
                }   
    
            );
    
    
        } else {
            console.error('No file selected');
    
            const docEquity = doc(database, "captable", currentUser.uid);
    
           
            if(allEquity==0 &&  editEquity < sharedholderEquity ){
              const remainingEquity = sharedholderEquity - editEquity;
            await updateDoc(docEquity,{
              totalEquity:increment(posToNeg(remainingEquity))
            })
    
           }else if(allEquity !=0 &&  editEquity > sharedholderEquity){
            const remainingEquity = editEquity - sharedholderEquity;

             await updateDoc(docEquity,{
               totalEquity:increment(remainingEquity) 
             })
           }


        
           await updateDoc(doc(database, "captable", currentUser.uid, "shareholders",documentID), {
             sharedholderName: editName,
             equity: editEquity,
             photoURL:"",
             createdAt: getCurrentDate()
           });
            setDisableUploadButton(false);
        }
        onClose();
    };

    const onSubmit = async(data) => {
      await handleFileUploadToFirebaseStorage(data.name,data.equity)   
    };

    const onSubmitCaptableData = async (data, e) => {
        e.preventDefault();
        const isValid = validateForm(data);
        if (isValid) {
          console.log("name" + editName)
          console.log("equity" + editEquity)
    
          handleFileUploadToFirebaseStorage();
          document.getElementsByClassName("upload-file-msg")[0].style.display = "block";
         
        }
    
    
    
      }

      
  return (
    
      <FormControl  onSubmit={handleSubmit(onSubmitCaptableData)}>
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          disableBackdropClick
          fullWidth
          maxWidth="xs"
          PaperProps={{ sx: { borderRadius: "10px" } }}

        >
          <DialogTitle id="customized-dialog" sx={{
            color: "#1C1C1C",
            fontFamily: "boldsans",
            fontSize: "1.125rem",
            fontStyle: "normal",
          }}>
            {"Edit Shareholder"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{
            color: " #1C1C1C",
            fontFamily: "dmsans",
            fontSize: " 0.875rem",
            fontStyle: "normal",
            fontWeight: 400,
          }}>

            Shareholder Photo
            <div className="user-image">
              <img
                className="user-profile-pic"
                src={fileName ? fileName : process.env.PUBLIC_URL + "/pic/user_profile.png"}

                alt="shareholder"

              />
              <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} />


              <Button variant='text' onClick={handleClick} sx={{
                color: "black", textTransform: "none",
                fontFamily: "dmsans",
                fontSize: " 0.75rem",
                fontWeight: 500,

              }}>Upload shareholder photo</Button>
            </div>

          </DialogContent>
          <DialogContent>
            <FormGroup
            >

              <FormLabel sx={{
                color: " #1C1C1C",
                fontFamily: "boldsans",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 700,
              }}>{"Shareholder Name"}</FormLabel>

              
<Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Name is required' }}
                                render={({ field }) => <TextField {...field} fullWidth />}
                            />

              {errors.name && <p className="error-text">{errors.name.message}</p>}

            </FormGroup>
          </DialogContent>
          <DialogContent>

            <FormGroup>
              <FormLabel sx={{
                color: " #1C1C1C",
                fontFamily: "boldsans",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 700,
              }}>{"Equity"}</FormLabel>

<Controller
                                name="equity"
                                control={control}
                                defaultValue=""
                                rules={{ 
                                 required: 'Equity is required',             

                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        placeholder="25%"
                                        fullWidth
                                        inputProps={{ min:min, max: totalEquity }}
                                        onChange={(e) => {
                                          //  handleEquityChange(e);
                                            field.onChange(e);
                                          }}
                                    />
                                )}
                            />
              
              <TextField
                type="number"
                placeholder="25%"
                fullWidth
                inputProps={{min: min,max: totalEquity }}
                {...register("equity", {
                  required: "Equity is required",
                })}
                defaultValue={editEquity}

                onChange={(e) => {
                   var equity = parseInt(e.target.value, 10);

                   if (equity > totalEquity) equity = totalEquity;
                  
                 
        
                  setEditEquity(equity);
                }}


              />
              {errors.equity && <p className="error-text">{errors.equity.message}</p>}

            </FormGroup>


          </DialogContent>


          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button type='submit' sx={{ marginLeft: "20px", marginTop: "40px", marginBottom: "30px", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} variant='outlined' autoFocus >
              Update

            </Button>
            <div className='upload-file-msg'>Saved</div>
          </DialogActions>
        </BootstrapDialog>

      </FormControl>

 
  )
}
