import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme => theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme => theme.spacing(1),
    },
}));

export default function KPiForm({ open, onClose }) {
    const {  handleSubmit, control,reset, formState: { errors } } = useForm();
    const { currentUser } = useAuth();


    const onDataSubmitted = async (data) => {
        await addDoc(collection(database, "kpis", currentUser.uid, "data"), {
            kpiName: data.kpiName,
            kpiValue: data.kpiValue,
            createdAt:Timestamp.fromMillis(data.kpiDate) 
        });

        document.getElementsByClassName("upload-file-msg")[0].style.display = "block";


        
        onClose();
        reset();


    }


    return (
       
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}

                disableBackdropClick
                fullWidth
                borderRadius="10px"
                maxWidth="xs"
            >
                <DialogTitle sx={{ marginLeft: 2, p: 2 }} id="customized-dialog-title">
                    {"Add KPIs"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton> 
                <form onSubmit={handleSubmit(onDataSubmitted)} >
                <FormControl fullWidth>
                <DialogContent>
                    <FormGroup>
                        <FormLabel>KPI Title</FormLabel>

                        <Controller
                            name="kpiName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Name is required' }}
                            render={({ field }) => <TextField {...field} fullWidth />}
                        />
                        {errors.kpiName && <p className="error-text">{errors.kpiName.message}</p>}

                    </FormGroup>

                </DialogContent>
                <DialogContent>
                    <FormGroup>
                        <FormLabel>KPI Value</FormLabel>
                 
                        <Controller
                            name="kpiValue"
                            type="number"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Value is required' }}
                            render={({ field }) => <TextField {...field} fullWidth />}
                        />

                        {errors.kpiValue && <p className="error-text">{errors.kpiValue.message}</p>}

                    </FormGroup>


                </DialogContent>

                <DialogContent>
                    <FormGroup >
                        <FormLabel >Kpi Date</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="kpiDate"
                                control={control}
                                defaultValue={null}
                                rules={{ required: "Date is required" }}
                                render={({ field }) => <DatePicker {...field}  />}
                            />
                            {errors.kpiDate && <p className="error-text">{errors.kpiDate.message}</p>}

                        </LocalizationProvider>
                    </FormGroup>
                </DialogContent>
                </FormControl>


                <DialogActions style={{ justifyContent: "space-between" }}>
                    <Button type='submit' disabled={false} sx={{ marginTop: "2rem", marginBottom: "2rem", marginLeft: "20px", width: "30%", textTransform: "none", borderColor: "black", color: "black " }} variant='outlined' autoFocus >
                        Save

                    </Button>
                    <div className='upload-file-msg'>Saved</div>
                </DialogActions>
                </form>

            </BootstrapDialog>
    )
}
