import React, { useState } from 'react'
import LableBackButton from "../../../component/LabelBackButton"
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import "./PreMoneyVal.css";
import { useForm } from 'react-hook-form';


export default function PreMoneyVal() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const navigate = useNavigate()
  const [roundAmount, setRoundAmount] = useState('');
  const [equity, setEquity] = useState('');

  return (
    <div>
      <LableBackButton title={"Pre-Money Valuation"} />
      <div className='mon-text'>Monetary Unit</div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="euro"
            name="radio-buttons-group"
          >
            <FormControlLabel className='radio-cp' value="euro" control={<Radio />} label="Euro (€)" />
            <FormControlLabel value="dollar" control={<Radio />} label="US Dollar ($USD)" />
            <FormControlLabel value="pound" control={<Radio />} label="British Pound (£)" />
          </RadioGroup>
          <Box sx={{width:"50%"}}>  
          <Stack spacing={2} sx={{marginTop:"4rem"}}>
          <FormGroup >
              <FormLabel className='label-text' >Round Amount you want to raise</FormLabel>
              <TextField
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                    e.preventDefault()
                  }
                }}
                placeholder="Ex: 3000.000"
              value={roundAmount}
                // className="form-control"
                {...register("roundAmount", {
                  required: "Round amout is required",
                 
                })}
                onChange={(e) => setRoundAmount(e.target.value)}

                error={!!errors.roundAmount}
              />
              {errors.roundAmount && <p className="error-text">{errors.roundAmount.message}</p>}
            </FormGroup>
            <FormGroup >
              <FormLabel className='lable-text' >Equity given to the round</FormLabel>
              <TextField
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                    e.preventDefault()
                  }
                }}
                placeholder="Ex: 15%"
              value={equity}
                // className="form-control"
                {...register("equity", {
                  required: "Equity is required",
                 
                })}
                onChange={(e) => setEquity(e.target.value)}

                error={!!errors.equity}
              />
              {errors.equity && <p className="error-text">{errors.equity.message}</p>}
            </FormGroup>


          </Stack>
         
          </Box>
        
        </FormControl>

        <Button  variant='outlined' sx={{ marginTop: "40px", textTransform: "none", width: "20%", borderRadius: "5px", borderColor: "black", color: "black", fontFamily: 'dmsans', fontSize: '0.9rem' }}>{"Continue"}</Button>

      </div>
    </div>
  )
}
