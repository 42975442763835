import React from 'react'
import BackButton from './BackButton'
import PageHeadingLabel from './PageHeadingLabel/PageHeadingLabel'

export default function LabelBackButton(props) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap:"1.5rem"
        }}>
            <BackButton customFunction={props.customFunction}/>
            <PageHeadingLabel headingText={props.title} />
        </div>
    )
}
