
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth,payments } from './/../../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2'
import { Button, FormControl, FormGroup, FormLabel, IconButton, InputAdornment, TextField } from "@mui/material";
import logo from "../../assets/dozerologo.png";
import "./login.css"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EmailResetDialog from '../../component/EmailResetDialog';
import isEmail from 'validator/lib/isEmail';
import { onCurrentUserSubscriptionUpdate } from '@invertase/firestore-stripe-payments';
const Login = () => {
  const {handleSubmit, control, formState: { errors } } = useForm();

  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);


  const [isPasswordVisible, setPasswordVisibility] = useState(true);

  function handleClickShowPassword() {
    setPasswordVisibility((isPasswordVisible) => !isPasswordVisible);
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
 




  const onLogin = async (data) => {

      await signInWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("user.emailVerified " + user.emailVerified);

          if (!user.emailVerified) {
            // navigate('/memberships', { replace: true });
 navigate('/emailverified', { replace: true });
          }
          
          else {
            console.log("subscription code");
            const subscriptionUpdateListener = onCurrentUserSubscriptionUpdate(
              payments,
              (snapshot) => {
                console.log("subscription snapshot " +snapshot.empty);

                if(snapshot.empty){
                  navigate('/memberships', { replace: true });
                }else{
                  for (const change of snapshot.changes) {
                    console.log("change.subscription.status " + change.subscription.status ==="trialing");
                    if (change.subscription.status === 'active' || change.subscription.status === 'trialing' ) {
                      navigate('/', { replace: true });
                    } else {
                      navigate('/memberships', { replace: true });
                    }
                  }
                }

               
          
              },(error)=>{
                console.log("subscription error " +error );

              }
            )
  
            return () => {
              subscriptionUpdateListener();
            };
          }

  
          
          console.log(user);
        })
        .catch((error) => {

          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(errorCode, errorMessage)
          if (error.code === "auth/email-already-in-use") {
            showErrorDialog("The email address is already in use");

          

          } else if (error.code === "auth/invalid-email") {
            showErrorDialog("The email address is not valid");

         

          } else if (error.code === "auth/operation-not-allowed") {
            showErrorDialog("Operation not allowed.");

          

          } else if (error.code === "auth/weak-password") {
            showErrorDialog("The password is too weak.");

        

          } else if (error.code === "auth/user-not-found") {
            showErrorDialog("The user is not found.");

          
          }
          else if (error.code === "auth/wrong-password") {
            showErrorDialog("The password is wrong.");

           

          }
          else if (error.code === "auth/invalid-login-credentials") {
            showErrorDialog("Login credentials are invalid");

           
          }

          else if (error.code === "auth/too-many-requests") {
            showErrorDialog("Your account is temporary blocked ,Please try again later");

           
          }
        });
    



  }

 function showErrorDialog (errorMessage){
    return Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: errorMessage,
      showConfirmButton: true,

    })

  }
  const isEmailValid = (value) => {
    return isEmail(value) || "Invalid email address";
  };


  const myStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/pic/background.png"})`,
    height: '95vh',
    width: '50vw',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (

    <>

      <div className="logo"><img src={logo} style={{}} alt='Dozero logo'></img></div>



      <div className='login-main'>
        <div className="image-container">
          <div style={myStyle} alt="login" />

          <div className="login-text-overlay">“Dozero made the investment preparation process so much  easier. Their step-by-step guidance was invaluable.”</div>
          <div className="login-text-overlay-founder">Jacob McDany - Founder</div>
        </div>
        <form onSubmit={handleSubmit(onLogin)}>
        <div className='login-container'>
          <h1 className='title'>Welcome to Dozero</h1>
          
          <FormControl fullWidth>
            <FormGroup >
              <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}>Email</FormLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required', validate: isEmailValid
                }}
                render={({ field }) => <TextField {...field} fullWidth type="email" placeholder="elon@musk.com" />}
              />

              {errors.email && <p className="error-text">{errors.email.message}</p>}
            </FormGroup>
           <br/>
            <FormGroup className="mb-3">
              <FormLabel sx={{ color: "black", fontFamily: 'boldsans', fontSize: "0.9rem" }}> Password</FormLabel>
           
        
                  <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                }}
                render={({ field }) => <TextField {...field}  fullWidth type={isPasswordVisible ? "password" : "text"} placeholder="your password"
                InputProps={{
                  endAdornment:

                    <InputAdornment position="end">

                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >{isPasswordVisible ?
                        <Visibility /> : <VisibilityOff />}


                      </IconButton>
                    </InputAdornment>
                }} />}
              />
         
              {errors.password && <p className="error-text">{errors.password.message}</p>}
            </FormGroup>



          </FormControl>
        
          <Button
            onClick={handleOpenDialog}
            variant="text" sx={{
              display: 'inline',
              color: "black",
              marginRight: "13.5rem",
              marginTop: "0.5rem",
              fontFamily: 'boldsans',
              fontSize: " 0.8rem",
              textTransform: "none"
            }}> Forget password</Button>
          <div className='button-wrapper'>
            <Button type="submit"  color="secondary" variant="outlined" sx={{ color: "black", width: "137px", borderColor: "black", textTransform: "none" }}> Login</Button>
          </div>
          <div className='register'>Not register yet? <NavLink style={{ alignItems: "center", paddingLeft: 10, color: "black" }} to={"/register"}> Create an account</NavLink>  </div>


        </div>
        </form>
        
      </div>

      <EmailResetDialog open={isDialogOpen} onClose={handleCloseDialog} />



    </>

  )
}

export default Login