import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Rating } from '@mui/material';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection } from "firebase/firestore";
import { database } from '../../firebase';
import { useAuth } from '../../hooks/AuthContext';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { useForm } from 'react-hook-form';
import AddMeetingDialog from './AddMeetingDialog';

const stageItemWithColors = {
    'All stages': ['#1C1C1C',"#D9D9D9"],
    'Meeting': ['#AB486D',"#FCE3F1"],
    'Due Diligence': ['#0B8800',"#9CDB96"],
    'Partner Meeting': ['#55918B',"#C2D8D7"],
    'Term Sheet': ['#00A7B1',"#66D3D6"],
    'Closed': ['#0066FF',"#6699FF"],
    'Keep in touch': ['#FF8585',"#FFB6B6"],
    'Passed': ['#FF0000',"#FF6666"],
};

function getStageTextColor(stageName) {
    return stageItemWithColors[stageName][0] || 'Stage not found';
}

function getStageBackgroundColor(stageName) {
    return stageItemWithColors[stageName][1] || 'Stage not found';
}





function formatDate(timestampDate) {
    if(timestampDate){
        console.log("timestampDATE "+ timestampDate)
        const timestamp = timestampDate.toDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(timestamp);
        const day = timestamp.getDate();
        const year = timestamp.getFullYear();
        return `${month} ${day}, ${year}`;
    }
    
}



export default function InvestmentTable() {
   
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [investment, setInvestment] = useState({});




    const handleOpenDialog = (data) => {

        setInvestment(data);
      setOpen(true);
    };
  
    const handleCloseDialog = () => {
      setOpen(false);
    };


    let myRef = collection(database, 'findInvestors', currentUser.uid, "investors")


    const [value, loading, error] = useCollection(
        myRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    return (
        <>
        <div>
      
        <AddMeetingDialog open={open} onClose={handleCloseDialog} meeting={investment} />
      </div>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">

                <TableRow>
                    <TableCell align='left'>Investor</TableCell>
                    <TableCell align="left">Stage</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Primary contact</TableCell>
                    <TableCell align="left">Rating</TableCell>
                    <TableCell align="left">Initial contact</TableCell>
                    <TableCell align="left">Next follow-up</TableCell>
                    <TableCell align="left">Edit</TableCell>



                </TableRow>


                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Checking data...</span>}
            
                {value && value.docs.map((docData) => (
                    <TableBody>

                        <TableRow
                            key={docData.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{docData.data().name}</TableCell>
                            <TableCell align="left" >
                            
                           {/* <span  style={{background:"#FCE3F1",color:"#AB486D",fontWeight:"bold",padding:"2px"}}>  {docData.data().stage}</span> */}
                           <span  style={{background:getStageBackgroundColor(docData.data().stage),color:getStageTextColor(docData.data().stage),fontWeight:"bold",padding:"5px"}}>  {docData.data().stage}</span>

                                </TableCell>
                            <TableCell align="left">{"$" + docData.data().amount}</TableCell>
                            <TableCell align="left">{docData.data().primaryContact}</TableCell>
                            <TableCell align="left">
                                <Rating
                                    readOnly 
                                    precision={0.5}       
                                    value={docData.data().rating}
                                   
                                />
                            </TableCell>
                            <TableCell align="left">{

                                formatDate(docData.data().initialContact)



                            }</TableCell>
                            <TableCell align="left">{
                                formatDate(docData.data().nextFollowUp)
                            }</TableCell>
                            <TableCell align="left">
                                <IconButton onClick={()=>handleOpenDialog({ ...docData.data(), docID: docData.id })}><BorderColorSharpIcon/>
                                </IconButton>
                                </TableCell>

                        </TableRow>
                    </TableBody>
                ))}
            </Table>
        </TableContainer>

        </>
    );
}