import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { Delete, Download } from '@mui/icons-material';
import "./FileListItem.css"
import { doc, deleteDoc } from "firebase/firestore";
import { database } from '../../../firebase';
import { useAuth } from '../../../hooks/AuthContext';
import DeleteFileDialog from '../../../component/DeleteFileDialog';



function downloadFile(url){
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      const blobUrl = window.URL.createObjectURL(xmlHttp.response);
      const e = document.createElement('a');
      e.href = blobUrl;
      e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
      document.body.appendChild(e);
      e.click();
      document.body.removeChild(e);
    }
  };
  xmlHttp.responseType = 'blob';
  xmlHttp.open('GET', url, true);
  xmlHttp.send(null);
}



async function deleteDocument(docID,colName,uid,title){
  let docRef;
   if (title === "3 minutes Pitch (Video)") {
    docRef = doc(database, "startupopportunity", uid,"pitch",docID);


  } else if (title === "3 minutes on UX (Video)") {
    docRef = doc(database, "startupopportunity", uid,"ux",docID);



  } else if (title === "3 minutes on Tech (Video)") {

    docRef = doc(database, "startupopportunity", uid,"tech",docID);



  } else if(title==="pitchStory"){
  
    docRef = doc(database, "pitchStory", uid,"data",docID);

 } else if (colName ==="incubator"){
  docRef = doc(database, "startupInformation", uid,colName,docID);

 }

  else{
    docRef = doc(database, "dataRoom", uid,colName,docID);
  }
  console.log("docID " + docID);
  console.log("colName " + colName);
  console.log("docRef " + docRef.path);

  await deleteDoc(docRef);

}
export default function FileListItem({colName,  docID, fileName, fileCreatedDate,fileUrl ,title}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  
  console.log(" FileListItem colName  "  + colName);

  const {currentUser} = useAuth()

    return (
        <div style={{ paddingLeft: "25px", paddingRight: "25px",marginTop:"10px" ,height: "80px", border: "1px solid #1C1C1C", borderRadius: "10px", display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <div> <p className='title-text'>{fileName}</p>
                <p className='data-date'>{fileCreatedDate}</p></div>

            <div>
              <IconButton  onClick={()=>downloadFile(fileUrl)} > 
              <Download sx={{ color: "black", border: "1px solid black", borderRadius: "5px" }} />
              </IconButton>
                <IconButton onClick={() => { setDialogOpen(true) }}>
                  <Delete sx={{ color: "#FB9B9B", border: "1px solid #FB9B9B", borderRadius: "5px" }} />
                  </IconButton></div>
                <DeleteFileDialog
                  deleteText={"Are you sure you want to delete this file? This action cannot be undone."}
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  onDelete={() => deleteDocument(docID,colName, currentUser.uid,title)}
                />
        </div>
    )
}
