import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {sendEmailVerification } from "firebase/auth";


const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };


  const forgetPassword = (email)=>{
    return 
  }

  const logout = () => {
    return auth.signOut();
  };

  const emailVerification = () => {
    return sendEmailVerification(auth.currentUser);
    

  };

  useEffect(() => {

    
    const unsubscribe = auth.onAuthStateChanged((user) => {

      if(user){
        console.log("AuthProvider " + user.email);

      }else{
        console.log("AuthProvider: user is currently logged out ");

      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
   currentUser,
   emailVerification,
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
