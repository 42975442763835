import React, { useEffect, useRef, useState } from 'react'
import { collection } from "firebase/firestore";
import { database } from '../../firebase';
import FileListItem from '../FileList/FileListItem/FileListItem';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuth } from '../../hooks/AuthContext';




export default function FileList(props) {
  const { currentUser } = useAuth();
  const[emtpyMessage,setEmptyMessage] = useState('');

  let ref =useRef();

  



  const styles = {
    color: 'black',
    fontFamily: 'dmsans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };



  
  useEffect(()=>{
  if (props.isComingFromStartup) {
    ref.current = collection(database, 'startupInformation', currentUser.uid, props.colName)
    setEmptyMessage("")

  }
  else if (props.isComingFromKPI) {
    ref.current = collection(database, 'kpis', currentUser.uid, "data")
    setEmptyMessage("")


  }
  else if (props.isComingFromPitch) {
    ref.current = collection(database, 'pitchStory', currentUser.uid, "data")
    setEmptyMessage("")


  }
  else if (props.colName === "3 minutes Pitch (Video)") {
    ref.current = collection(database, 'startupopportunity', currentUser.uid, "pitch")
    setEmptyMessage("You have not yet set up your video.")


  } else if (props.colName === "3 minutes on UX (Video)") {
    ref.current = collection(database, 'startupopportunity', currentUser.uid, "ux")

    setEmptyMessage("You have not yet set up your video.")


  } else if (props.colName === "3 minutes on Tech (Video)") {

    ref.current = collection(database, 'startupopportunity', currentUser.uid, "tech")
    setEmptyMessage("You have not yet set up your video.")




  }
  else if (props.colName === "Pitch Story") {

    ref.current = collection(database, 'pitchStory', currentUser.uid, "data")
    setEmptyMessage("")



  }
  else {
    ref.current = collection(database, 'dataRoom', currentUser.uid, (props.colName.split(' ')[0]).toLowerCase())
        setEmptyMessage("You haven't added any document yet.")


  }
  },[]);

  
  const [value, loading, error,empty] = useCollection(
    ref.current,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  console.log("ref " + ref.path);


  return (
    <div>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span style={styles}>Checking data...</span>}


      {!loading && empty && <p style={styles}>{emtpyMessage}</p>}

      {value && (
        
        <span>


          {value.docs.map((doc) => (

            <React.Fragment key={doc.id}>
              {(() => {
                if (props.isComingFromStartup) {
                  return <ul style={{ padding: "0" }} key={doc.id}>
                    <FileListItem colName={(props.colName.split(' ')[0]).toLowerCase()} docID={doc.id} fileUrl={doc.data().incubatorName} fileName={doc.data().incubatorName} fileCreatedDate={doc.data().website} />
                  </ul>;
                } else if (props.isComingFromKPI) {
                  return <ul style={{ padding: "0" }} key={doc.id}>
                  <FileListItem colName={(props.colName.split(' ')[0]).toLowerCase()} docID={doc.id} fileUrl={doc.data().incubatorName} fileName={doc.data().kpiName} fileCreatedDate={doc.data().kpiValue} />
                </ul>;
                }
                else if (props.isComingFromPitch) {
                  return <ul style={{ padding: "0" }} key={doc.id}>
                  <FileListItem title={props.colName} colName={(props.colName.split(' ')[0]).toLowerCase()} docID={doc.id} fileUrl={doc.data().fileUrl} fileName={doc.data().fileName} fileCreatedDate={doc.data().createdAt} />
                </ul>;
                }
                
                else {
                  return <ul style={{ padding: "0" }} key={doc.data().fileUrl}><FileListItem title={props.colName} colName={(props.colName.split(' ')[0]).toLowerCase()} docID={doc.id} fileUrl={doc.data().fileUrl} fileName={doc.data().fileName} fileCreatedDate={doc.data().createdAt} /></ul>

                }
              })()}

            </React.Fragment>
          ))}
        </span>
      )}

     




    </div>
  )
}

