import React from 'react';
import logo from "../../assets/dozerologo.png";
import { NavLink, useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
      <div>
       <div>
          <NavLink className="nav-menu-links logo" exact activeClassName="active" to="/" replace={true}>
            dozero
          </NavLink>
          
        </div>
         <div style={{width:"100%",height:"100vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",justifyItems:"center"}} ><h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p></div>
     
      </div>
     
  );
};

export default NotFound;
