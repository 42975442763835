import React, { useState } from 'react'
import PageTopComponent from "../../component/PageTopComponent/PageTopComponent"
import KpiGroup from './KPIGroup';
import KPiForm from './KPiForm';

const KPI = () => {
    const [closeDialog, setCloseDialog] = useState(false);






  const handleDialogClose = (event, reason) => {
    //prevent user to close the dialog by pressing on the page.
    if (reason && reason === "backdropClick") {
        return;
    }
    setCloseDialog((closeDialog)=> !closeDialog);
  

};

  return (
    <>  
  <PageTopComponent title={"KPIs"} buttonText={"Add"} onClick={handleDialogClose} />

  <KPiForm onClose={handleDialogClose} open={closeDialog}/>
    <KpiGroup/>

    </>
  
  ) 
}

export default KPI